import { analytics } from '../../../../shared/firebase/firebase'; // Ensure the path is correct
import { logEvent } from 'firebase/analytics';
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const logAnalyticsEvent = (eventName) => {
  logEvent(analytics, `loggedout_homepage_MobileFAQ_${eventName}`);
};

const FAQItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div
      style={{
        width: "100%",
        boxShadow: "0px 6px 24px rgba(223, 230, 236, 0.32)",
        borderRadius: "24px",
        backgroundColor: "#fff",
        border: "1px solid #f7f7f7",
        padding: "22px 23px",
        marginBottom: "4px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <div style={{ flex: "1", fontWeight: "500" }}>{question}</div>
        <button
          style={{
            backgroundColor: "#423FD3",
            borderRadius: "50%",
            border: "none",
            width: "39px",
            height: "39px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="white"
            width="24px"
            height="24px"
          >
            <path d={isOpen ? "M8 12l4-4 4 4" : "M12 16l4-4H8z"} />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div
          style={{
            marginTop: "24px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#979797",
          }}
        >
          {answer}
        </div>
      )}
    </div>
  );
};

FAQItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const FrameComponent = ({ className = "" }) => {
  const { t } = useTranslation(); // Initialize translation hook
  const [openIndex, setOpenIndex] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();

  const faqs = [
    {
      question: t("FrameComponent.faq_1_question", { defaultValue: "How does SkillQ work?" }),
      answer: t("FrameComponent.faq_1_answer", { defaultValue: "SkillQ generates questions based on the job description you provide. You verify these questions, and then our AI conducts a conversational interview with the candidate, guiding them toward high-scoring responses." }),
    },
    {
      question: t("FrameComponent.faq_2_question", { defaultValue: "How does the assessment process look like for my candidate?" }),
      answer: t("FrameComponent.faq_2_answer", { defaultValue: "The system generates a unique link that you should share with the candidate to complete the assessment. Once the candidate has finished, you can view the results on SkillQ dashboard." }),
    },
    {
      question: t("FrameComponent.faq_3_question", { defaultValue: "How is the candidate assessed?" }),
      answer: t("FrameComponent.faq_3_answer", { defaultValue: "The candidate is evaluated based on predefined criteria—suggested by the system but customizable as needed—and their recorded responses. Our model analyzes these responses, aligning them with the criteria to deliver a comprehensive assessment." }),
    },
    {
      question: t("FrameComponent.faq_4_question", { defaultValue: "How much does it cost?" }),
      answer: t("FrameComponent.faq_4_answer", { defaultValue: "It's free to try! The first three candidate interviews are free, and subsequent interviews are available through subscription plans and bulk interview purchases. You can visit our pricing page for more details." }),
    },
  ];

  const handleToggle = (index) => {
    logAnalyticsEvent(`question_${index}_toggle`);
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleShowMore = () => {
    logAnalyticsEvent(showMore ? 'show_less' : 'show_more');
    setShowMore(!showMore);
  };

  const handleContactRedirect = () => {
    logAnalyticsEvent('contact_us_click');
    navigate('/ContactForm');
  };

  const visibleFaqs = showMore ? faqs : faqs.slice(0, 3);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px 16px",
        boxSizing: "border-box",
        fontFamily: "Poppins",
      }}
      className={className}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "24px",
        }}
      >
        <div
          style={{
            borderRadius: "70px",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            border: "1px solid #ececfb",
            padding: "6px 15px",
            textTransform: "uppercase",
            fontWeight: "600",
          }}
        >
          {t("FrameComponent.faq_label", { defaultValue: "FAQ" })}
        </div>
        <h1
          style={{
            margin: "20px 0",
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: "600",
            color: "#17172f",
            textAlign: "center",
          }}
        >
          <span>{t("FrameComponent.ask_your", { defaultValue: "Ask your " })}</span>
          <span style={{ color: "#423FD3" }}>{t("FrameComponent.question", { defaultValue: "question, " })}</span>
          <span>{t("FrameComponent.get_answered", { defaultValue: "get answered" })}</span>
        </h1>
        <div
          style={{
            fontSize: "16px",
            color: "#434356",
            lineHeight: "24px",
            fontWeight: "500",
            textAlign: "center",
            marginBottom: "16px",
          }}
        >
          {t("FrameComponent.subtitle", { defaultValue: "Explore Our Comprehensive Frequently Asked Questions for In-Depth Insights" })}
        </div>
        <b
          style={{
            fontSize: "16px",
            color: "#423FD3",
          }}
        >
          {t("FrameComponent.cant_find", { defaultValue: "Can’t find answers?" })}
        </b>
        <button
          style={{
            cursor: "pointer",
            border: "none",
            padding: "12px 48px",
            backgroundColor: "#423FD3",
            borderRadius: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            marginTop: "20px",
            color: "#fff",
            fontSize: "16px",
            fontWeight: "600",
            textTransform: "uppercase",
          }}
          onClick={handleContactRedirect}
        >
          {t("FrameComponent.contact_us", { defaultValue: "Contact us" })}
          <img
            src={`${process.env.PUBLIC_URL}/images/landing/Vector.webp`}
            alt="Vector"
            style={{
              height: "0.75rem",
              width: "0.75rem",
            }}
          />
        </button>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "24px",
        }}
      >
        {visibleFaqs.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            isOpen={openIndex === index}
            onClick={() => handleToggle(index)}
          />
        ))}
        <button
          onClick={handleShowMore}
          style={{
            cursor: "pointer",
            border: "none",
            padding: "12px 48px",
            backgroundColor: "#ECECFB",
            borderRadius: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            color: "#423FD3",
            fontSize: "16px",
            fontWeight: "600",
            textTransform: "uppercase",
          }}
        >
          {t(showMore ? "FrameComponent.show_less" : "FrameComponent.show_more", { defaultValue: showMore ? "Show less" : "Show more" })}
        </button>
      </div>
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
