import PropTypes from "prop-types";

const TopComponent = ({ className = "" }) => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "300px", // Adjust the height as needed
        width: "100%",
      }}
      className={className}
    >
      <img
        style={{
          width: "60%",
          height: "auto",
          objectFit: "contain",
        }}
        alt="Main Image"
        src={`${process.env.PUBLIC_URL}/images/landing/Group 9387.webp`}
      />
    </div>
  );
};

TopComponent.propTypes = {
  className: PropTypes.string,
};

export default TopComponent;
