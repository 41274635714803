import React from "react";
import { useTranslation } from "react-i18next";  // Import i18n hook
import PropTypes from "prop-types";

const Footer = ({ className = "" }) => {
  const { t } = useTranslation();  // Initialize translation

  return (
    <footer
      style={{
        alignSelf: "stretch",
        backgroundColor: "#1c1a59",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-end",
        padding: "275px 128px 40px",
        boxSizing: "border-box",
        gap: "73px",
        maxWidth: "100%",
        marginTop: "-139px",
        textAlign: "left",
        fontSize: "18px",
        color: "#fff",
        fontFamily: "Poppins",
      }}
      className={className}
    >
      <div
        style={{
          width: "1073px",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "0px 2px",
          boxSizing: "border-box",
          maxWidth: "100%",
        }}
      >
        <div
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "49px",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "34px",
              minWidth: "492px",
              maxWidth: "100%",
            }}
          >
            <img
              style={{
                width: "203.4px",
                height: "64px",
                position: "relative",
                objectFit: "cover",
              }}
              loading="lazy"
              alt=""
              src={`${process.env.PUBLIC_URL}/skillQ_logo 2.webp`}
            />
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "13px",
              }}
            >
              <div style={{ position: "relative", fontWeight: "500" }}>
                {t('HomePageConcept.Footer.about_company', { defaultValue: "About the Company" })}
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "16px",
                  color: "#fafafb",
                }}
              >
                {t('HomePageConcept.Footer.company_description', {
                  defaultValue: "SkillQ is an innovative AI-powered platform that transforms tech talent assessment, connecting vetted professionals with top employers and facilitating continuous skill advancement."
                })}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "263px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
              fontSize: "16px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  height: "32px",
                  width: "32px",
                  borderRadius: "100px",
                  backgroundColor: "#fff",
                  overflow: "hidden",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    width: "34px",
                    height: "34px",
                    position: "relative",
                  }}
                  alt=""
                  src={`${process.env.PUBLIC_URL}/images/landing/ic_round-email.webp`}
                />
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "3px 0px 0px",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    lineHeight: "26px",
                    fontWeight: "500",
                    whiteSpace: "nowrap",
                  }}
                >
                  info@skillq.org
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "4px 0px 0px",
                }}
              >
                <div
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "100px",
                    backgroundColor: "#fff",
                    overflow: "hidden",
                    flexShrink: "0",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "34px",
                      height: "34px",
                      position: "relative",
                    }}
                    alt=""
                    src={`${process.env.PUBLIC_URL}/images/landing/ic_round-email (1).webp`}
                  />
                </div>
              </div>
              <div
                style={{
                  flex: "1",
                  position: "relative",
                  lineHeight: "20px",
                  textTransform: "capitalize",
                  fontWeight: "500",
                }}
              >
                <p style={{ margin: "0" }}>{t('HomePageConcept.Footer.address_line_1', { defaultValue: "Singapore, 68" })}</p>
                <p style={{ margin: "0" }}>{t('HomePageConcept.Footer.address_line_2', { defaultValue: "Circular Road, #02-01" })}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          gap: "31px",
          fontSize: "16px",
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            height: "1px",
            backgroundColor: "#fff",
            margin: "0",
          }}
        />
        <div
          style={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "4px 0px 0px",
            }}
          >
            <div style={{ position: "relative" }}>
              © 2024 SkillQ. {t('HomePageConcept.Footer.all_rights_reserved', { defaultValue: "All rights reserved." })}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "24px",
            }}
          >
            <a href="https://x.com/skill_q" target="_blank" rel="noopener noreferrer">
              <img
                style={{
                  height: "32px",
                  width: "32px",
                  position: "relative",
                  borderRadius: "100px",
                  overflow: "hidden",
                  flexShrink: "0",
                  minHeight: "32px",
                }}
                loading="lazy"
                alt="Twitter"
                src={`${process.env.PUBLIC_URL}/images/landing/Frame 6765.webp`}
              />
            </a>
            <a href="https://www.youtube.com/channel/UCPCYrZk6b2xFz_rUrO2Y6sg" target="_blank" rel="noopener noreferrer">
              <img
                style={{
                  height: "32px",
                  width: "32px",
                  position: "relative",
                  overflow: "hidden",
                  flexShrink: "0",
                  minHeight: "32px",
                }}
                loading="lazy"
                alt="LinkedIn"
                src={`${process.env.PUBLIC_URL}/images/landing/Vector (1).webp`}
              />
            </a>
            <a href="https://www.linkedin.com/company/skill-q/" target="_blank" rel="noopener noreferrer">
              <img
                style={{
                  height: "32px",
                  width: "32px",
                  position: "relative",
                  borderRadius: "100px",
                  overflow: "hidden",
                  flexShrink: "0",
                  minHeight: "32px",
                }}
                loading="lazy"
                alt="YouTube"
                src={`${process.env.PUBLIC_URL}/images/landing/Frame 6764.webp`}
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
