import { analytics } from '../../../../shared/firebase/firebase'; // Ensure the path is correct
import { logEvent } from 'firebase/analytics';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next'; // Import the translation hook

const BottomComponent = ({ className = "" }) => {
  const { t } = useTranslation(); // Initialize translation hook

  const logAnalyticsEvent = (eventName) => {
    logEvent(analytics, `loggedout_homepage_MobileAssessmentScreenImage_${eventName}`);
  };

  const handleButtonClick = () => {
    logAnalyticsEvent('try_it_now_click');
    window.location.href = "/register/company";
  };

  const handleImageLoad = () => {
    logAnalyticsEvent('image_load');
  };

  return (
    <div
      style={{
        width: "100%",
        margin: "0",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        boxSizing: "border-box",
        zIndex: "4",
        textAlign: "left",
        fontSize: "23px",
        color: "#0b0b24",
        fontFamily: "Poppins",
      }}
      className={className}
    >
      <button
        style={{
          cursor: "pointer",
          border: "none",
          padding: "12px 48px",
          backgroundColor: "#423fd3",
          borderRadius: "100px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          whiteSpace: "nowrap",
        }}
        onClick={handleButtonClick}
      >
        <div
          style={{
            fontSize: "16px",
            textTransform: "uppercase",
            fontWeight: "600",
            fontFamily: "Poppins",
            color: "#fff",
            textAlign: "center",
          }}
        >
          {t("HomePageConcept.BottomComponent.try_now")}
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/images/landing/Vector.webp`}
          alt="Vector"
          style={{
            margin: "0",
            height: "0.75rem",
            width: "0.75rem",
            position: "relative",
            overflow: "hidden",
            flexShrink: "0",
          }}
        />
      </button>
      <img
        style={{
          width: "90%",
          marginTop: "40px",
          marginBottom: "40px",
        }}
        loading="lazy"
        alt={t("HomePageConcept.BottomComponent.image_alt")}
        src={`${process.env.PUBLIC_URL}/images/landing/Group 9388.webp`}
        onLoad={handleImageLoad}
      />
    </div>
  );
};

BottomComponent.propTypes = {
  className: PropTypes.string,
};

export default BottomComponent;
