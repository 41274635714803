import { analytics } from '../../../shared/firebase/firebase'; // Ensure the path is correct
import { logEvent } from 'firebase/analytics';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';  // Import i18n hook

const WhatsAppButton = ({ phoneNumber, message, position = 'right', src, width = '60', height = '60' }) => {
  const { t } = useTranslation();  // Initialize translation

  useEffect(() => {
    const css = `
      .fixed-whatsapp-button {
        position: fixed;
        bottom: 20px;
        ${position}: 20px;
        z-index: 999;
        text-align: center;
      }

      @keyframes bounce {
        0%,
        20%,
        60%,
        100% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
        }

        40% {
          -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
        }

        80% {
          -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
        }
      }

      .fixed-whatsapp-button:hover {
        animation: bounce 0.5s;
      }

      .whatsapp-text {
        font-size: 12px;
        color: #25d366;
        margin-top: 5px;
      }

      @media (max-width: 600px) {
        .fixed-whatsapp-button img {
          width: 45px;
          height: 45px;
        }
        .whatsapp-text {
          font-size: 10px;
        }
      }
    `;

    const style = document.createElement('style');
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));

    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [position]);

  const isMobile = window.innerWidth <= 600;
  const encodedPhoneNumber = encodeURIComponent(phoneNumber);
  const whatsappLink = isMobile
    ? `https://wa.me/${encodedPhoneNumber}`
    : `https://api.whatsapp.com/send?phone=${encodedPhoneNumber}&text=${encodeURIComponent(message)}`;

  const handleButtonClick = () => {
    logEvent(analytics, 'loggedout_homepage_whatsappbutton_click');
  };

  return (
    <div className="fixed-whatsapp-button">
      <a href={whatsappLink} target="_blank" rel="noopener noreferrer" onClick={handleButtonClick}>
        <img src={src} width={width} height={height} alt="WhatsApp" draggable="false" />
      </a>
      <div>
        <div className="whatsapp-text">{t('HomePageConcept.WhatsAppButton.any_questions', { defaultValue: 'Any questions?' })}</div>
        <div className="whatsapp-text">{t('HomePageConcept.WhatsAppButton.text_us', { defaultValue: 'Text us' })}</div>
      </div>
    </div>
  );
};

export default WhatsAppButton;
