import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";  // Import i18n hook
import PropTypes from "prop-types";

const Content = ({ className = "", screenShot, text, name, position, setHeight }) => {
  const { t } = useTranslation();  // Initialize translation
  const contentRef = useRef(null);

  React.useEffect(() => {
    if (contentRef.current) {
      setHeight(contentRef.current.offsetHeight);
    }
  }, [setHeight]);

  return (
    <div
      ref={contentRef}
      style={{
        flex: "1",
        borderRadius: "16px",
        backgroundColor: "#fafaff",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "32px",
        boxSizing: "border-box",
        minWidth: "284px",
        maxWidth: "100%",
        textAlign: "left",
        fontSize: "16px",
        color: "#000",
        fontFamily: "Poppins",
        height: "100%", // Ensure it stretches to the full height
      }}
      className={className}
    >
      <div
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "36px",
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            position: "relative",
            lineHeight: "150%",
          }}
        >
          {t(text)}  {/* Use translation for the text content */}
        </div>
      </div>
      <div
        style={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
        }}
      >
        <img
          style={{
            height: "56px",
            width: "56px",
            position: "relative",
            borderRadius: "126px",
            objectFit: "cover",
          }}
          loading="lazy"
          alt=""
          src={screenShot}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              position: "relative",
              lineHeight: "150%",
              fontWeight: "600",
              display: "inline-block",
              minWidth: "112px",
            }}
          >
            {t(name)}  {/* Use translation for the name */}
          </div>
          <div
            style={{
              position: "relative",
              fontSize: "13px",
              lineHeight: "20px",
              color: "#767676",
            }}
          >
            {t(position)}  {/* Use translation for the position */}
          </div>
        </div>
      </div>
    </div>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  screenShot: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  setHeight: PropTypes.func.isRequired,
};

export default Content;
