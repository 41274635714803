import { analytics } from '../../../../shared/firebase/firebase'; // Ensure the path is correct
import { logEvent } from 'firebase/analytics';

import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'; // Import the translation hook

const MobileTrySkillQ = ({ className = "" }) => {
  const { t } = useTranslation(); // Initialize translation hook
  const navigate = useNavigate();

  const logAnalyticsEvent = (eventName) => {
    logEvent(analytics, `loggedout_homepage_MobileCallToAction_${eventName}`);
  };

  const handleGetStartedClick = () => {
    logAnalyticsEvent('get_started_click');
    navigate('/register/company');
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        boxSizing: "border-box",
        fontSize: "24px", // Smaller font size for mobile
        color: "#050222",
        fontFamily: "Poppins",
        background: "linear-gradient(to bottom, #fff 50%, #1c1a59 50%)",
      }}
      className={className}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "600px", // Adjusted for mobile view
          borderRadius: "24px",
          backgroundColor: "rgba(236, 236, 251, 0.5)",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "12px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            width: "100%",
            borderRadius: "16px",
            backgroundColor: "#fbfbfb",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "40px 20px", // Adjusted padding for mobile view
            boxSizing: "border-box",
          }}
        >
          <h2
            style={{
              margin: "0 0 20px 0", // Adjusted margin for spacing
              fontSize: "26px", // Adjusted for mobile view
              lineHeight: "32px",
              fontWeight: "600",
              fontFamily: "Poppins",
              color: "#423fd3",
            }}
          >
            {t("HomePageConcept.TrySkillQBackground.try_skillq")}
            <span style={{ color: "#423fd3" }}>{t("HomePageConcept.TrySkillQBackground.skillq")}</span>
            {t("HomePageConcept.TrySkillQBackground.now")}
          </h2>
          <div
            style={{
              fontSize: "16px", // Adjusted for mobile view
              lineHeight: "24px",
              fontWeight: "500",
              color: "#6a6a6a",
              textAlign: "center",
              marginBottom: "20px", // Spacing between text and button
            }}
          >
            {t("HomePageConcept.TrySkillQBackground.first_3_interviews")}
          </div>
          <button
            style={{
              cursor: "pointer",
              border: "none",
              padding: "16px 32px", // Adjusted padding for mobile view
              backgroundColor: "#423fd3",
              borderRadius: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              color: "#fff",
              fontSize: "16px",
              fontWeight: "600",
              textTransform: "uppercase",
            }}
            onClick={handleGetStartedClick}
          >
            {t("HomePageConcept.TrySkillQBackground.get_started")}
            <img
              src={`${process.env.PUBLIC_URL}/images/landing/Vector.webp`}
              alt="Vector"
              style={{
                marginLeft: "8px", // Spacing between text and icon
                height: "0.75rem",
                width: "0.75rem",
              }}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

MobileTrySkillQ.propTypes = {
  className: PropTypes.string,
};

export default MobileTrySkillQ;
