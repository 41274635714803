import React from "react";
import { useTranslation } from 'react-i18next';  // Import the i18next hook
import useMediaQuery from '@mui/material/useMediaQuery';
import HeroSection from "./components/HeroSection";
import StaticsSection from "./components/StaticsSection";
import GroupComponent from "./components/GroupComponent";
import ValuePropositionContainer from "./components/ValuePropositionContainer";
import SplitVideoScreen from "./components/SplitVideoScreen";
import CandidateBackground from "./components/CandidateBackground";
import FrameComponent from "./components/FrameComponent";
import TrySkillQBackground from "./components/TrySkillQBackground";
import Footer from "./components/Footer";
import MobileHeroSection from "./components_mobile/MobileHeroSection";
import MobileStaticsSection from "./components_mobile/MobileStaticsSection";
import MobileGroupComponent from "./components_mobile/MobileGroupComponent";
import MobileValuePropositionContainer from "./components_mobile/MobileValuePropositionContainer";
import MobileSplitVideoScreen from "./components_mobile/MobileSplitVideoScreen";
import MobileCandidateBackground from "./components_mobile/MobileCandidateBackground";
import MobileFrameComponent from "./components_mobile/MobileFrameComponent";
import MobileTrySkillQ from "./components_mobile/MobileTrySkillQ";
import MobileFooter from "./components_mobile/MobileFooter";
import WhatsAppButton from "./WhatsAppButton";

const HomePageConcept = () => {
  const { t } = useTranslation();  // Initialize translation hook

  return (
    <div>
      <HeroSection />
      <StaticsSection />
      <GroupComponent />
      <section>
        <ValuePropositionContainer />
        <SplitVideoScreen />
      </section>
      <section>
        <CandidateBackground />
        <FrameComponent />
      </section>
      <section>
        <TrySkillQBackground />
        <Footer />
      </section>
      <WhatsAppButton
        phoneNumber="+79854798604"
        message={t('whatsapp_message', { defaultValue: "Hello, would you help me?" })}  // Translated or default text
        src={`${process.env.PUBLIC_URL}/images/landing/whatsapp-logo.webp`}
        position="right"
      />
    </div>
  );
};

const MobileHomePageConcept = () => {
  const { t } = useTranslation();  // Initialize translation hook

  return (
    <div>
      <MobileHeroSection />
      <MobileStaticsSection />
      <MobileGroupComponent />
      <MobileValuePropositionContainer />
      <MobileSplitVideoScreen />
      <MobileCandidateBackground />
      <MobileFrameComponent />
      <MobileTrySkillQ />
      <MobileFooter />
      <WhatsAppButton
        phoneNumber="+79854798604"
        message={t('HomePageConcept.WhatsAppButton.message', { defaultValue: "Hello, would you help me?" })}  // Updated translation key
        src={`${process.env.PUBLIC_URL}/images/landing/whatsapp-logo.webp`}
        position="right"
      />
    </div>
  );
};

const HomePage = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  return isMobile ? <MobileHomePageConcept /> : <HomePageConcept />;
};

export default HomePage;
