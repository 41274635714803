import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { Container, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';

const StyledContainer = styled(Container)(({ theme }) => ({
  position: 'relative',
  width: '50%',
  paddingTop: '28.125%',
  height: 0,
  overflow: 'hidden',
  backgroundColor: '#F9FAFB',
  marginBottom: theme.spacing(4),
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    width: '80%',
    paddingTop: '45%',
  },
}));

const PlayerWrapper = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

const FullScreenVideo = ({ videoUrl, onClose }) => {
  return (
    <StyledContainer disableGutters>
      <IconButton
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          zIndex: 1,
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: '#f0f0f0',
          },
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <PlayerWrapper>
        <ReactPlayer
          url={videoUrl}
          className='react-player'
          width='100%'
          height='100%'
          controls
        />
      </PlayerWrapper>
    </StyledContainer>
  );
};

FullScreenVideo.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FullScreenVideo;
