import { analytics } from '../../../../../shared/firebase/firebase'; // Ensure the path is correct
import { logEvent } from 'firebase/analytics';
import { useTranslation } from 'react-i18next'; // Import translation hook
import PropTypes from "prop-types";

const BottomComponent = ({ className = "" }) => {
  const { t } = useTranslation(); // Initialize translation hook
  const logAnalyticsEvent = (eventName) => {
    logEvent(analytics, `loggedout_homepage_AssessmentScreenImage_${eventName}`);
  };

  const handleButtonClick = () => {
    logAnalyticsEvent('try_it_now_click');
    window.location.href = "/register/company";
  };

  return (
    <div
      style={{
        width: "100%",
        margin: "0",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        boxSizing: "border-box",
        zIndex: "4",
        textAlign: "left",
        fontSize: "23px",
        color: "#0b0b24",
        fontFamily: "Poppins",
        marginTop: "-120px", // Adjust this value to shift the entire component higher
      }}
      className={className}
    >
      <div
        style={{
          width: "70%", // Adjust to make the container smaller
          maxWidth: "500px",
          margin: "20px 0",
          textAlign: "center",
        }}
      >
        <div
          style={{
            fontSize: "3.0vh", // Approximately 40px on 1400px screen
            lineHeight: "4vh", // Approximately 56px on 1400px screen
            fontWeight: "500"
          }}
        >
          <span style={{ color: "#423FD3" }}>
            {t('HomePageConcept.BottomComponent.interview_evaluate', { defaultValue: 'Interview and evaluate' })}
          </span>
          <span> </span>
          <span style={{ color: "#000" }}>
            {t('HomePageConcept.BottomComponent.candidates_scale', { defaultValue: 'candidates at scale with AI voice agent' })}
          </span>
          </div>
      </div>
      <img
        style={{
          width: "80%",
          maxWidth: "1000px",
          marginTop: "20px",
        }}
        loading="lazy"
        alt="Large Visual"
        src={`${process.env.PUBLIC_URL}/images/landing/Group 9388 (5).webp`}
        onLoad={() => logAnalyticsEvent('image_load')}
      />
      <button
        style={{
          cursor: "pointer",
          border: "none",
          padding: "12px 48px",
          backgroundColor: "#423fd3",
          borderRadius: "100px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          marginTop: "20px",
          whiteSpace: "nowrap",
        }}
        onClick={handleButtonClick}
      >
        <div
          style={{
            fontSize: "16px",
            textTransform: "uppercase",
            fontWeight: "600",
            fontFamily: "Poppins",
            color: "#fff",
            textAlign: "center",
          }}
          >
          {t('HomePageConcept.BottomComponent.try_now', { defaultValue: 'Try it now' })}
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/images/landing/Vector.webp`}
          alt="Vector"
          style={{
            margin: "0",
            height: "0.75rem",
            width: "0.75rem",
            position: "relative",
            overflow: "hidden",
            flexShrink: "0",
          }}
        />
      </button>
    </div>
  );
};

BottomComponent.propTypes = {
  className: PropTypes.string,
};

export default BottomComponent;
