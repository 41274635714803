// fetchIpData.js

const fetchIpData = () => {
  return new Promise((resolve, reject) => {
    const ajax = new XMLHttpRequest();
    if (window.location.href.includes("localhost")) {
      /**
       *  Resolve with dummy data, GET call will be rejected,
       *  since ipinfo's server is configured that way
       */
      resolve({ country: "DE" });
      return;
    }
    ajax.open("GET", "https://ipinfo.io/json");
    ajax.onload = () => {
      const response = JSON.parse(ajax.responseText);
      if (response) {
        resolve(response);
      } else {
        reject(new Error("No response data"));
      }
    };
    ajax.onerror = () => reject(new Error("Request failed"));
    ajax.send();
  });
};

export default fetchIpData;
