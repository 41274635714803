import { analytics } from '../../../../shared/firebase/firebase'; // Ensure the path is correct
import { logEvent } from 'firebase/analytics';

import React, { useState } from "react";
import { useTranslation } from "react-i18next";  // Import i18n hook
import PropTypes from "prop-types";
import './ValueProposition.css';

// Subcomponent: Header
const Header = () => {
  const { t } = useTranslation();  // Initialize translation hook

  return (
    <div className="valuePropositionHeaderContainer">
      <div>
        <div className="valuePropositionHeaderTagline">
          {t('HomePageConcept.ValueProposition.why_choose_us', { defaultValue: "Why Choose Us" })}
        </div>
      </div>
      <h1 className="valuePropositionHeaderTitle">
        {t('HomePageConcept.ValueProposition.fast_track', { defaultValue: "Fast track your high volume hiring" })}
      </h1>
      <div className="valuePropositionHeaderDescription">
        {t('HomePageConcept.ValueProposition.description', {
          defaultValue: "SkillQ delivers two-way conversational interviews with a potential candidate and gives you immediate insights at a fraction of your current costs."
        })}
      </div>
    </div>
  );
};

// Subcomponent: BulletPoint
const BulletPoint = ({ title, description, imageSrc, isOpen, onClick }) => {
  return (
    <div className={`valuePropositionBulletPointContainer ${isOpen ? 'open' : ''}`}>
      <div onClick={onClick} className={`valuePropositionBulletPointHeader ${isOpen ? 'open' : ''}`}>
        <img className="valuePropositionBulletPointIcon" alt="" src={imageSrc} />
        <h3 className="valuePropositionBulletPointTitle">{title}</h3>
        <img className="valuePropositionBulletPointArrow" alt="" src={isOpen ? `${process.env.PUBLIC_URL}/images/landing/formkit_arrowright.png` : `${process.env.PUBLIC_URL}/images/landing/formkit_arrowright_1.png`} />
      </div>
      {isOpen && (
        <div className="valuePropositionBulletPointDescription">
          {description}
        </div>
      )}
    </div>
  );
};

BulletPoint.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  imageSrc: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

// Subcomponent: LeftSide
const LeftSide = ({ bulletPoints, openBullet, setOpenBullet }) => {
  const logAnalyticsEvent = (eventName) => {
    logEvent(analytics, `loggedout_homepage_ValueProposition_${eventName}`);
  };

  return (
    <div className="valuePropositionLeftSideContent">
      {bulletPoints.map((bullet, index) => (
        <div className="valuePropositionBulletWrapper" key={index}>
          <BulletPoint
            title={bullet.title}
            description={bullet.description}
            imageSrc={bullet.imageSrc}
            isOpen={openBullet === index}
            onClick={() => {
              logAnalyticsEvent(`bullet_${index}_click`);
              setOpenBullet(openBullet === index ? null : index);
            }}
          />
        </div>
      ))}
    </div>
  );
};

LeftSide.propTypes = {
  bulletPoints: PropTypes.array.isRequired,
  openBullet: PropTypes.number,
  setOpenBullet: PropTypes.func.isRequired,
};

// Subcomponent: RightSide
const RightSide = ({ rightImage }) => {
  const logAnalyticsEvent = (eventName) => {
    logEvent(analytics, `loggedout_homepage_ValueProposition_${eventName}`);
  };

  return (
    <div className="valuePropositionRightSideContent">
      {rightImage && (
        <img
          className="valuePropositionRightSideImage"
          alt="Bullet point image"
          src={rightImage}
          onLoad={() => logAnalyticsEvent('right_image_load')}
        />
      )}
    </div>
  );
};

RightSide.propTypes = {
  rightImage: PropTypes.string,
};

// Main component: ValueProposition
const ValueProposition = () => {
  const { t } = useTranslation();  // Initialize translation hook
  const [openBullet, setOpenBullet] = useState(0); // Default to first bullet open

  const bulletPoints = [
    {
      title: t('HomePageConcept.ValueProposition.bullet_1_title', { defaultValue: "SkillQ adapts to a candidate" }),
      description: (
        <p>
          {t('HomePageConcept.ValueProposition.bullet_1_description', {
            defaultValue: "Interview feels like a conversation with a real person, no more awkwardness of a one-way video recording format."
          })}
        </p>
      ),
      imageSrc: `${process.env.PUBLIC_URL}/images/landing/fluent_video-person-16-filled.webp`,
      rightImage: `${process.env.PUBLIC_URL}/images/landing/Group 9392.webp`,
    },
    {
      title: t('HomePageConcept.ValueProposition.bullet_2_title', { defaultValue: "You can customise the interview" }),
      description: (
        <p>
          {t('HomePageConcept.ValueProposition.bullet_2_description', {
            defaultValue: "SkillQ will create questions and evaluation criteria based on the job description provided. You can edit the suggestion as needed."
          })}
        </p>
      ),
      imageSrc: `${process.env.PUBLIC_URL}/images/landing/heroicons_adjustments-horizontal-20-solid.webp`,
      rightImage: `${process.env.PUBLIC_URL}/images/landing/Group 9395.webp`,
    },
    {
      title: t('HomePageConcept.ValueProposition.bullet_3_title', { defaultValue: "SkillQ provides objective assessment" }),
      description: (
        <p>
          {t('HomePageConcept.ValueProposition.bullet_3_description', {
            defaultValue: "As soon as the interview is completed, you get a fair assessment of a candidate to help guide hiring decision."
          })}
        </p>
      ),
      imageSrc: `${process.env.PUBLIC_URL}/images/landing/mdi_medal.webp`,
      rightImage: `${process.env.PUBLIC_URL}/images/landing/Group 9394.webp`,
    },
  ];

  return (
    <div className="valuePropositionHeroSection">
      <Header />
      <div className="valuePropositionContent">
        <div className="valuePropositionLeftSide">
          <LeftSide bulletPoints={bulletPoints} openBullet={openBullet} setOpenBullet={setOpenBullet} />
        </div>
        <div className="valuePropositionRightSide">
          {bulletPoints[openBullet] && (
            <RightSide rightImage={bulletPoints[openBullet].rightImage} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ValueProposition;
