import React from 'react';
import { Button } from '@mui/material';
import { sendEmailVerification } from 'firebase/auth';
import { useTranslation } from 'react-i18next'; // Import i18n hook

const ResendEmailNotification = ({ user, onResent }) => {
  const { t } = useTranslation(); // Initialize translation hook

  const resendVerificationEmail = async () => {
    try {
      await sendEmailVerification(user);
      onResent();  // Call the callback function to notify the parent component
    } catch (error) {
      console.error('Error resending email verification:', error);
    }
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={resendVerificationEmail}
    >
      {t('SignupPage.ResendEmailNotification.button_text', { defaultValue: 'Resend Email Verification' })}
    </Button>
  );
};

export default ResendEmailNotification;
