import React from "react";
import { useTranslation } from "react-i18next"; // Import i18n hook
import PropTypes from "prop-types";
import "./StaticsSection.css";

const StaticsSection = ({ className = "" }) => {
  const { t } = useTranslation(); // Initialize translation hook

  // Move statsData inside the component where `t` is accessible
  const statsData = [
    { number: "150+", description: t('HomePageConcept.StaticsSection.hours_saved', { defaultValue: "hours of the technical team saved" }) },
    { number: "99%", description: t('HomePageConcept.StaticsSection.cheating_detection', { defaultValue: "ChatGPT cheating detection" }) },
    { number: "+50%", description: t('HomePageConcept.StaticsSection.assessment_completion_increase', { defaultValue: "assessment completion rate increase" }) },
    { number: "95%", description: t('HomePageConcept.StaticsSection.candidate_experience_rating', { defaultValue: "of the candidates rate the experience 5/5" }) },
  ];

  return (
    <section className={`section ${className}`}>
      <div className="inner-div">
        <h1 className="title">
          <p style={{ margin: "0" }}>
            <span>{t('HomePageConcept.StaticsSection.embrace_conversational', { defaultValue: "Embrace conversational" })}</span>
            <span style={{ color: "#37374b" }}>{` `}</span>
            <span style={{ color: "#423fd3" }}>{t('HomePageConcept.StaticsSection.ai_benefits', { defaultValue: "AI benefits" })}</span>
            <span style={{ color: "#37374b" }}>{` `}</span>
          </p>
          {t('HomePageConcept.StaticsSection.for_your_company', { defaultValue: "for your company" })}
        </h1>
        <div className="stats-div">
          {statsData.map((stat, index) => (
            <div key={index} className="stat-item">
              <div className="stat-number">{stat.number}</div>
              <div className="stat-description">{stat.description}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="divider" />
    </section>
  );
};

StaticsSection.propTypes = {
  className: PropTypes.string,
};

export default StaticsSection;
