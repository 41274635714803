import React, { useEffect } from 'react';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../../shared/firebase/firebase';
import FirebaseAuthComponent from './FirebaseAuthComponent';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next'; // Import i18n hook

const LeftGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: '#f0f0f0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  textAlign: 'center',
  height: '100vh',
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const BrandImage = styled('img')({
  width: '150px',
  marginBottom: '20px',
});

const CompanySignup = () => {
  const { t } = useTranslation(); // Initialize translation hook

  useEffect(() => {
    logEvent(analytics, 'loggedout_CompanyRegistration_business');
  }, []);

  const handleSignupSuccess = (user) => {
    if (window.gtag) {
      window.gtag('set', 'user_properties', {
        user_id: user.uid,
      });
    }
    console.log("User profile and subscription status created successfully, navigating to user profile page.");
  };

  const handleSignupError = (error) => {
    console.error("Signup error", error);
  };

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', m: 0, p: 0, width: '100vw', overflow: 'hidden' }}>
      <Grid container sx={{ flex: 1, m: 0, p: 0 }}>
        <LeftGrid item xs={12} md={6}>
          <Typography variant="h2" gutterBottom>
            {t('SignupPage.CompanySignup.stay_ahead', { defaultValue: 'Stay ahead of the curve' })}
          </Typography>
          <Typography variant="h6" color="textSecondary" paragraph>
            {t('SignupPage.CompanySignup.expand_interview_bandwidth', {
              defaultValue: 'Expand your interviewing bandwidth, speed up candidate evaluations, and clinch more placements - in the competitive landscape of talent acquisition',
            })}
          </Typography>
          <BrandImage src={`${process.env.PUBLIC_URL}/skillQ_logo.png`} alt="SkillQ" />
        </LeftGrid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', boxSizing: 'border-box' }}>
          <Paper elevation={6} sx={{ padding: 4, textAlign: 'center', maxWidth: '400px', width: '100%', m: 0, boxShadow: { xs: 'none', sm: 6 } }}>
            <Typography variant="h4" gutterBottom>
              {t('SignupPage.CompanySignup.welcome', { defaultValue: 'Welcome' })} <span role="img" aria-label="wave">👋</span>
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              {t('SignupPage.CompanySignup.interview_candidates_at_scale', { defaultValue: 'Interview and evaluate candidates at scale, with ease' })}
            </Typography>
            <FirebaseAuthComponent onSignupSuccess={handleSignupSuccess} onSignupError={handleSignupError} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanySignup;
