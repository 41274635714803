import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import yaml from "js-yaml";
import remarkGfm from "remark-gfm";
import rehypeSlug from "rehype-slug";
import './Article.css'; // Import the CSS file for styling

const Article = () => {
  const { articleName } = useParams();
  const [content, setContent] = useState("");
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    const filePath = `${process.env.PUBLIC_URL}/blog/${articleName}.md`;

    fetch(filePath)
      .then((response) => {
        if (!response.ok) {
          console.error(`Failed to fetch ${filePath}: ${response.statusText}`);
          return "";
        }
        return response.text();
      })
      .then((text) => {
        const regex = /^---\n([\s\S]*?)\n---\n?([\s\S]*)$/;
        const match = text.match(regex);

        if (match) {
          const yamlText = match[1];
          const markdownContent = match[2];

          const data = yaml.load(yamlText);
          setMetadata(data);

          // Remove anchor tags from content
          let cleanedContent = markdownContent.replace(
            /<a\s+name=['"][^'"]*['"]><\/a>/gi,
            ""
          );

          // Remove HTML comments
          cleanedContent = cleanedContent.replace(/<!--[\s\S]*?-->/g, "");

          setContent(cleanedContent);
        } else {
          setContent(text);
        }
      })
      .catch((error) => {
        console.error(`Error fetching article ${filePath}:`, error);
      });
  }, [articleName]);

  return (
    <div className="article-container">
      {/* Display Metadata */}
      <h1 className="article-title">{metadata.title}</h1>
      <p className="article-description">{metadata.description}</p>
      {metadata.hero && metadata.hero.image && (
        <img
          src={`${process.env.PUBLIC_URL}/images/${metadata.hero.image.file}`}
          alt={metadata.hero.image.alt}
          className="article-hero-image"
        />
      )}

      {/* Render Markdown Content */}
      <div className="article-content">
        <ReactMarkdown
          children={content}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeSlug]}
        />
      </div>
    </div>
  );
};

export default Article;
