import { analytics } from '../../../../../shared/firebase/firebase'; // Ensure the path is correct
import { logEvent } from 'firebase/analytics';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import the translation hook
import LanguageSwitcher from '../../../../../shared/components/LanguageSwitcher'; // Import LanguageSwitcher

const MobileHeader = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSolutionsOpen, setIsSolutionsOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setIsSolutionsOpen(false); // Close Solutions submenu when main menu toggles
    logEvent(analytics, 'loggedout_homepage_MobileHeader');
  };

  const logAnalyticsEvent = (eventName) => {
    logEvent(analytics, `loggedout_homepage_${eventName}`);
  };

  return (
    <header
      style={{
        width: "calc(100% - 9vw)",
        margin: "0 3vw 0vh 3vw",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        top: "0",
        zIndex: "99",
        position: "sticky",
        textAlign: "left",
        fontSize: "1rem",
        color: "#000",
        fontFamily: "Poppins",
        padding: "0 3vw",
        boxSizing: "border-box",
      }}
    >
      <Link to="/" onClick={() => logAnalyticsEvent('logo_click')}>
        <img
          style={{
            height: "10vh",
            width: "auto",
            objectFit: "cover",
          }}
          loading="lazy"
          alt="SkillQ Logo"
          src={`${process.env.PUBLIC_URL}/skillQ_logo.png`}
        />
      </Link>

      <div
        onClick={toggleMenu}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          width: "30px",
          height: "30px",
          cursor: "pointer",
        }}
      >
        <div style={{ width: "100%", height: "3px", backgroundColor: "#000" }}></div>
        <div style={{ width: "100%", height: "3px", backgroundColor: "#000" }}></div>
        <div style={{ width: "100%", height: "3px", backgroundColor: "#000" }}></div>
      </div>

      {menuOpen && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            left: "0",
            width: "100%",
            backgroundColor: "#fff",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            zIndex: "98",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "flex-start",
          }}
        >
          {/* Add LanguageSwitcher as the first item in the hamburger menu */}
          <div
            style={{
              padding: "15px 20px",
              borderBottom: "1px solid #e0e0e0",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <LanguageSwitcher />
          </div>

          {/* Solutions Menu Item */}
          <div
            onClick={() => setIsSolutionsOpen(!isSolutionsOpen)}
            style={{
              textDecoration: "none",
              letterSpacing: "-0.01em",
              fontWeight: "500",
              color: "inherit",
              padding: "15px 20px",
              cursor: "pointer",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            {t('HomePageConcept.Header.solutions')}
          </div>
          {isSolutionsOpen && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f9f9f9",
              }}
            >
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  padding: "10px 40px",
                  color: "#000",
                }}
                onClick={() => {
                  setMenuOpen(false);
                  logAnalyticsEvent('general_interview_click');
                }}
              >
                {t('HomePageConcept.Header.general_interview')}
              </Link>
              <Link
                to="/home-page-tech"
                style={{
                  textDecoration: "none",
                  padding: "10px 40px",
                  color: "#000",
                }}
                onClick={() => {
                  setMenuOpen(false);
                  logAnalyticsEvent('technical_interview_click');
                }}
              >
                {t('HomePageConcept.Header.technical_interview')}
              </Link>
            </div>
          )}

          {/* Pricing Link */}
          <Link
            to="/pricing"
            style={{
              textDecoration: "none",
              letterSpacing: "-0.01em",
              fontWeight: "500",
              color: "inherit",
              padding: "15px 20px",
              borderBottom: "1px solid #e0e0e0",
            }}
            onClick={() => {
              setMenuOpen(false);
              logAnalyticsEvent('pricing_click');
            }}
          >
            {t('HomePageConcept.Header.pricing')}
          </Link>

          {/* Book a Demo Link */}
          <Link
            to="/ContactForm"
            style={{
              textDecoration: "none",
              letterSpacing: "-0.01em",
              fontWeight: "500",
              color: "inherit",
              padding: "15px 20px",
              borderBottom: "1px solid #e0e0e0",
            }}
            onClick={() => {
              setMenuOpen(false);
              logAnalyticsEvent('book_demo_click');
            }}
          >
            {t('HomePageConcept.Header.book_demo')}
          </Link>

          {/* Blog Link */}
          <Link
            to="/blog"
            style={{
              textDecoration: "none",
              letterSpacing: "-0.01em",
              fontWeight: "500",
              color: "inherit",
              padding: "15px 20px",
              borderBottom: "1px solid #e0e0e0",
            }}
            onClick={() => {
              setMenuOpen(false);
              logAnalyticsEvent('blog_click');
            }}
          >
            {t('HomePageConcept.Header.blog', { defaultValue: 'Blog' })}
          </Link>

          {/* Login Button */}
          <Link
            to="/login"
            style={{
              textDecoration: "none",
              padding: "15px 20px",
            }}
            onClick={() => {
              setMenuOpen(false);
              logAnalyticsEvent('login_click');
            }}
          >
            <button
              style={{
                width: "100%",
                cursor: "pointer",
                border: "none",
                padding: "10px",
                backgroundColor: "#423fd3",
                borderRadius: "5px",
                color: "#fff",
                fontSize: "1rem",
                letterSpacing: "-0.01em",
                fontWeight: "500",
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            >
              {t('HomePageConcept.Header.login')}
            </button>
          </Link>
        </div>
      )}
    </header>
  );
};

export default MobileHeader;
