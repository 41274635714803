import React, { Fragment, Suspense, lazy, useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import Pace from "./shared/components/Pace";
import { StoreProvider, useStore } from './StoreProvider';  // Adjust the import path as needed
import AccountTypeProtectedRoute from './AccountTypeProtectedRoute';
import { observer } from "mobx-react-lite";
import { auth } from './shared/firebase/firebase';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import LoggedOutComponent from "./logged_out/components/Main";


const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));
// const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));
const AssessmentComponent = lazy(() => import("./assessment/components/Main"));

const AuthObserver = observer(() => {
  const store = useStore();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      await store.initializeUser(authUser);
    });

    return () => unsubscribe();
  }, [store]);

  return null;
});

function App() {
  console.log("Rendering App component");

  return (
    <StoreProvider>
      <I18nextProvider i18n={i18n}>
        <AuthObserver />
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles />
            <Pace color={theme.palette.primary.light} />
            <Suspense fallback={<Fragment />}>
              <Routes>
                <Route path="/" element={<LoggedOutComponent />} />
                <Route
                  path="/c/*"
                  element={
                    <AccountTypeProtectedRoute allowedAccountTypes={['company']}>
                      <LoggedInComponent />
                    </AccountTypeProtectedRoute>
                  }
                />
                <Route
                  path="/u/*"
                  element={
                    <AccountTypeProtectedRoute>
                      <AssessmentComponent />
                    </AccountTypeProtectedRoute>
                  }
                />
                <Route
                  path="/test/*"
                  element={<AssessmentComponent />} // No protection for this path
                />
                <Route path="/*" element={<LoggedOutComponent />} />
              </Routes>
            </Suspense>
          </ThemeProvider>
        </BrowserRouter>
      </I18nextProvider>
    </StoreProvider>
  );
}

export default App;
