import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Import i18n hook

const SuccessBanner = ({ message, onClose }) => {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <Box sx={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      color: 'black',
      textAlign: 'center',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      zIndex: 1000,
      width: '80%',
      maxWidth: '500px'
    }}>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        {t('SignupPage.success_title', { defaultValue: 'Success!' })}
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        {message}
      </Typography>
      <Button variant="contained" color="primary" onClick={onClose}>
        {t('SignupPage.close_button', { defaultValue: 'Close' })}
      </Button>
    </Box>
  );
};

export default SuccessBanner;
