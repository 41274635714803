import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth, analytics } from '../../../shared/firebase/firebase';
import { useStore } from '../../../StoreProvider';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { runInAction } from 'mobx';
import { Container, Paper, Grid, TextField, Button, Typography, CircularProgress, Box, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import { logEvent } from 'firebase/analytics';
import { useTranslation } from 'react-i18next'; // Import i18n hook

const Root = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(10),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledPaper = styled(Paper)(({ theme, isMobile }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  boxShadow: isMobile ? 'none' : theme.shadows[1],
  border: isMobile ? 'none' : `1px solid ${theme.palette.divider}`,
}));

const Form = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const SuccessText = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const ForgotPasswordLink = styled('a')(({ theme }) => ({
  textAlign: 'center',
  color: 'blue',
  cursor: 'pointer',
  textDecoration: 'underline',
  marginTop: theme.spacing(2),
}));

const Login = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const store = useStore();
  const [cancel, setCancel] = useState(false);

  useEffect(() => {
    if (store.user && store.user.emailVerified) {
      navigateBasedOnAccountType(store.accountType || 'default', store.onboardingPassed);
    }
  }, [store.user]);

  const handleRegisterRedirect = () => {
    logEvent(analytics, 'loggedout_Login_register_redirect');
    navigate('/register/company');
  };

  const navigateBasedOnAccountType = (accountType, onboardingPassed) => {
    if (cancel) return;

    accountType = accountType.trim().toLowerCase();
    if (accountType === 'company') {
      onboardingPassed ? navigate('/c/VacancyList') : navigate('/c/onboarding');
    } else if (accountType === 'user') {
      navigate('/u/interview-preparation');
    }
  };

  const signInWithEmail = async (e) => {
    e.preventDefault();
    setCancel(false);
    setLoading(true);

    logEvent(analytics, 'loggedout_Login_attempt', { method: 'Email' });

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      if (!userCredential.user.emailVerified) {
        setError(t('LoginPage.Login.email_not_verified', {
          defaultValue: "Please confirm your email address, in case the email is not received, please check spam folder."
        }));
        setLoading(false);
        return;
      }

      const db = getFirestore();
      const userDocRef = doc(db, "users", userCredential.user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const { accountType, personalInfo, onboardingPassed, companyName } = userDocSnap.data();
        runInAction(() => {
          store.user = userCredential.user;
          store.accountType = accountType;
          store.personalInfo = personalInfo;
          store.onboardingPassed = onboardingPassed === 1;
          store.companyName = companyName;
        });

        navigateBasedOnAccountType(accountType, onboardingPassed === 1);
      } else {
        setError(t('LoginPage.Login.no_user_data', { defaultValue: 'No user data found' }));
      }
    } catch (error) {
      setError(error.message);
      logEvent(analytics, 'loggedout_Login_failed', { method: 'Email', error: error.message });
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setCancel(false);

    if (!email || !validateEmail(email)) {
      setError(t('LoginPage.Login.invalid_email', { defaultValue: "Please enter a valid email address." }));
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess(t('LoginPage.Login.reset_instructions_sent', {
        defaultValue: "Password reset instructions have been sent to your email."
      }));
      logEvent(analytics, 'loggedout_Login_password_reset_request', { email });
    } catch (error) {
      setError(t('LoginPage.Login.reset_error', { defaultValue: "Error resetting password: " }) + error.message);
    }
  };

  return (
    <Root component="main" maxWidth="xs">
      <StyledPaper isMobile={isMobile}>
        <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: '60vh' }}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<InfoIcon />}
            onClick={handleRegisterRedirect}
            style={{ marginBottom: 10 }}
          >
            {t('LoginPage.Login.no_account', { defaultValue: "Don't have an account? Register" })}
          </Button>
          <Typography component="h1" variant="h5">
            {t('LoginPage.Login.login_title', { defaultValue: 'Login' })}
          </Typography>
          <Form onSubmit={signInWithEmail} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('LoginPage.Login.email_label', { defaultValue: "Email Address" })}
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError('');
                setSuccess('');
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('LoginPage.Login.password_label', { defaultValue: "Password" })}
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Box position="relative">
              <SubmitButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {t('LoginPage.Login.login_button', { defaultValue: "Login" })}
              </SubmitButton>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
            {error && <ErrorText>{error}</ErrorText>}
            {success && <SuccessText>{success}</SuccessText>}
          </Form>
          <ForgotPasswordLink onClick={handleForgotPassword}>
            {t('LoginPage.Login.forgot_password', { defaultValue: "Forgot password? Reset." })}
          </ForgotPasswordLink>
        </Grid>
      </StyledPaper>
    </Root>
  );
};

export default Login;
