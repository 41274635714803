import { analytics } from '../../../../shared/firebase/firebase'; // Ensure the path is correct
import { logEvent } from 'firebase/analytics';

import React, { useRef, useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const SplitScreenImage = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState('');

  const imageSrc = `${process.env.PUBLIC_URL}/images/landing/Group 9396.webp`;
  const videoPath = 'public/demo recording 2.mp4';

  const logAnalyticsEvent = (eventName) => {
    logEvent(analytics, `loggedout_homepage_VideoScreen_${eventName}`);
  };

  const handleImageClick = () => {
    setShowVideo(true);
    setIsLoading(true);
    logAnalyticsEvent('image_click');
  };

  useEffect(() => {
    // Fetch the video URL from Firebase Storage
    const fetchVideo = async () => {
      const storage = getStorage();
      const videoRef = ref(storage, videoPath);

      try {
        const url = await getDownloadURL(videoRef);
        setVideoUrl(url);
        setIsLoading(false);
        logAnalyticsEvent('video_load_success');
      } catch (error) {
        console.error('Failed to load video', error);
        logAnalyticsEvent('video_load_failure');
      }
    };

    if (showVideo) {
      fetchVideo();
    }
  }, [showVideo, videoPath]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener('ended', () => {
        logAnalyticsEvent('video_end');
        // Video has ended, handle any logic if needed
      });

      return () => {
        videoElement.removeEventListener('ended', () => {
          // Cleanup
        });
      };
    }
  }, [videoUrl]);

  const mediaStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    maxHeight: "70%",
    maxWidth: "70%",
  };

  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        width: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          height: "50%",
          backgroundColor: "#1c1a59",
        }}
      />
      <div
        style={{
          position: "absolute",
          bottom: "0",
          left: "0",
          right: "0",
          height: "50%",
          backgroundColor: "#fff",
        }}
      />
      {showVideo ? (
        isLoading ? (
          <div
            style={{
              ...mediaStyle,
              backgroundColor: "black",
            }}
          />
        ) : (
          <video
            ref={videoRef}
            src={videoUrl}
            controls
            autoPlay
            style={mediaStyle}
          />
        )
      ) : (
        <img
          src={imageSrc}
          alt="Split screen image"
          style={{ ...mediaStyle, cursor: "pointer" }}
          onClick={handleImageClick}
        />
      )}
    </div>
  );
};

export default SplitScreenImage;
