import { analytics } from '../../../../shared/firebase/firebase'; // Ensure the path is correct
import { logEvent } from 'firebase/analytics';

import React, { useState } from "react";
import { useTranslation } from "react-i18next";  // Import i18n hook
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import "./FrameComponent.css"; // Import the CSS file

const logAnalyticsEvent = (eventName) => {
  logEvent(analytics, `loggedout_homepage_FAQ_${eventName}`);
};

const FAQItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div
      style={{
        alignSelf: "stretch",
        boxShadow: "0px 6px 24px rgba(223, 230, 236, 0.32)",
        borderRadius: "24px",
        backgroundColor: "#fff",
        border: "1px solid #f7f7f7",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: "22px 23px",
        marginBottom: "24px",
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <div
          style={{
            flex: "1",
            fontWeight: "500",
            display: "inline-block",
          }}
        >
          {question}
        </div>
        <button
          style={{
            backgroundColor: "#423FD3",
            borderRadius: "50%",
            border: "none",
            width: "39px",
            height: "39px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="white"
            width="24px"
            height="24px"
          >
            <path d={isOpen ? "M8 12l4-4 4 4" : "M12 16l4-4H8z"} />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div
          style={{
            marginTop: "24px",
            alignSelf: "stretch",
            fontSize: "16px",
            fontWeight: "500",
            color: "#979797",
          }}
        >
          {answer}
        </div>
      )}
    </div>
  );
};

FAQItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const FrameComponent = ({ className = "" }) => {
  const { t } = useTranslation();  // Initialize translation hook
  const [openIndex, setOpenIndex] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();

  const faqs = [
    {
      question: t('HomePageConcept.FrameComponent.faq_1_question', { defaultValue: "How does SkillQ work?" }),
      answer: t('HomePageConcept.FrameComponent.faq_1_answer', {
        defaultValue: "SkillQ generates questions based on the job description you provide. You verify these questions, and then our AI conducts a conversational interview with the candidate, guiding them toward high-scoring responses",
      }),
    },
    {
      question: t('HomePageConcept.FrameComponent.faq_2_question', { defaultValue: "How does the assessment process look like for my candidate?" }),
      answer: t('HomePageConcept.FrameComponent.faq_2_answer', {
        defaultValue: "The system generates a unique link that you should share with the candidate to complete the assessment. Once the candidate has finished, you can view the results on SkillQ dashboard",
      }),
    },
    {
      question: t('HomePageConcept.FrameComponent.faq_3_question', { defaultValue: "How is the candidate assessed?" }),
      answer: t('HomePageConcept.FrameComponent.faq_3_answer', {
        defaultValue: "The candidate is evaluated based on predefined criteria—suggested by the system but customizable as needed—and their recorded responses. Our model analyzes these responses, aligning them with the criteria to deliver a comprehensive assessment",
      }),
    },
    {
      question: t('HomePageConcept.FrameComponent.faq_4_question', { defaultValue: "How much does it cost?" }),
      answer: t('HomePageConcept.FrameComponent.faq_4_answer', {
        defaultValue: "It's free to try! The first three candidate interviews are free, and subsequent interviews are available through subscription plans and bulk interview purchases. You can visit our pricing page here for more details",
      }),
    },
  ];

  const handleToggle = (index) => {
    logAnalyticsEvent(`question_${index}_toggle`);
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleShowMore = () => {
    logAnalyticsEvent(showMore ? 'show_less' : 'show_more');
    setShowMore(!showMore);
  };

  const handleContactRedirect = () => {
    logAnalyticsEvent('contact_us_click');
    navigate('/ContactForm');
  };

  const visibleFaqs = showMore ? faqs : faqs.slice(0, 3);

  return (
    <div
      style={{
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "91px",
        padding: "0px 88px 150px 104px",
        maxWidth: "100%",
        textAlign: "left",
        fontSize: "14px",
        color: "#423fd3",
        fontFamily: "Poppins",
      }}
      className={className}
    >
      <div
        style={{
          width: "412px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "40px",
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "24px",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "24px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "20px",
              }}
            >
              <div
                style={{
                  width: "62px",
                  borderRadius: "70px",
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  border: "1px solid #ececfb",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "6px 15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      letterSpacing: "0.02em",
                      textTransform: "uppercase",
                      fontWeight: "600",
                      display: "inline-block",
                      minWidth: "30px",
                    }}
                  >
                    {t('HomePageConcept.FrameComponent.faq_label', { defaultValue: "FAQ" })}
                  </div>
                </div>
              </div>
              <div className="faq-title">
                <span>
                  <p style={{ margin: "0" }}>
                    <span style={{ color: "#17172f" }}>{t('HomePageConcept.FrameComponent.ask_your', { defaultValue: "Ask your" })}</span>
                    <span className="highlight">{t('HomePageConcept.FrameComponent.question', { defaultValue: "question," })}</span>
                  </p>
                  <p style={{ margin: "0", color: "#17172f" }}>
                    {t('HomePageConcept.FrameComponent.get_answered', { defaultValue: "get answered" })}
                  </p>
                </span>
              </div>
            </div>
            <div className="faq-subtitle">
              <div
                style={{
                  alignSelf: "stretch",
                  lineHeight: "24px",
                  fontWeight: "500",
                }}
              >
                {t('HomePageConcept.FrameComponent.subtitle', {
                  defaultValue: "Explore Our Comprehensive Frequently Asked Questions for In-Depth Insights",
                })}
              </div>
            </div>
          </div>
          <b className="faq-cant-find">{t('HomePageConcept.FrameComponent.cant_find', { defaultValue: "Can’t find answers?" })}</b>
        </div>
        <button
          style={{
            cursor: "pointer",
            border: "none",
            padding: "12px 48px",
            backgroundColor: "#423fd3",
            borderRadius: "100px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            marginTop: "20px",
            whiteSpace: "nowrap",
          }}
          onClick={handleContactRedirect}
        >
          <div
            style={{
              fontSize: "16px",
              textTransform: "uppercase",
              fontWeight: "600",
              fontFamily: "Poppins",
              color: "#fff",
              textAlign: "center",
            }}
          >
            {t('HomePageConcept.FrameComponent.contact_us', { defaultValue: "Contact us" })}
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/images/landing/Vector.webp`}
            alt="Vector"
            style={{
              margin: "0",
              height: "0.75rem",
              width: "0.75rem",
              position: "relative",
              overflow: "hidden",
              flexShrink: "0",
            }}
          />
        </button>
      </div>
      <div
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "24px",
          fontSize: "18px",
          color: "#2d2d2d",
        }}
      >
        {visibleFaqs.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            isOpen={openIndex === index}
            onClick={() => handleToggle(index)}
          />
        ))}
        <button
          onClick={handleShowMore}
          style={{
            cursor: "pointer",
            border: "none",
            padding: "12px 48px",
            backgroundColor: "#ECECFB",
            borderRadius: "100px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            marginTop: "20px",
            whiteSpace: "nowrap",
            color: "#423FD3",
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Poppins",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          {showMore ? t('HomePageConcept.FrameComponent.show_less', { defaultValue: "Show less" }) : t('HomePageConcept.FrameComponent.show_more', { defaultValue: "Show more" })}
        </button>
      </div>
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
