import { analytics } from '../../../../../shared/firebase/firebase'; // Ensure the path is correct
import { logEvent } from 'firebase/analytics';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';  // Import i18n hook
import PropTypes from 'prop-types';
import { Modal, Box, useMediaQuery } from '@mui/material';
import FullScreenVideo from './FullScreenVideo';
import { useNavigate } from 'react-router-dom';

const LeftSide = () => {
  const { t } = useTranslation();  // Initialize translation hook
  const [showVideo, setShowVideo] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();

  const logAnalyticsEvent = (eventName) => {
    logEvent(analytics, `loggedout_homepage_MainScreen_${eventName}`);
  };

  const handleVideoOpen = () => {
    setShowVideo(true);
    logAnalyticsEvent('video_open');
  };

  const handleVideoClose = () => {
    setShowVideo(false);
    logAnalyticsEvent('video_close');
  };

  const handleRedirect = () => {
    navigate('/register/company');
    logAnalyticsEvent('get_started_click');
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: "4vh 3vw 8vh 6vw",
        boxSizing: "border-box",
        position: "relative",
        gap: "8vh",
        flexShrink: "0",
        width: "100%",
        zIndex: "2",
        marginTop: "8vh",
        fontFamily: "Poppins",
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          gap: "1vw",
          width: "100%",
        }}
      >
        <div
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            padding: "0 0 3vh 0",
            boxSizing: "border-box",
            width: "100%",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.3vh",
              width: "100%",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  position: "relative",
                  gap: "3vh",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "2vh",
                    width: "100%",
                    color: "#423fd3",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0",
                      boxSizing: "border-box",
                      gap: "2vh",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "70px",
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        border: "1px solid #ececfb",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        padding: "0.5vh 1vw",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            height: "3vh",
                            position: "relative",
                            letterSpacing: "0.02em",
                            textTransform: "uppercase",
                            fontWeight: "500",
                            display: "flex",
                            alignItems: "center",
                          }}
                          >
                          {t('HomePageConcept.LeftSide.smart_ai_agent', { defaultValue: "Smart AI voice agent" })}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                        fontSize: "3rem",
                        letterSpacing: "-0.02em",
                        lineHeight: "1.2",
                        fontWeight: "500",
                        display: "inline-block",
                        flexShrink: "0",
                        color: "#0b0b24",
                        textAlign: "left",
                      }}
                    >
                      <p style={{ margin: "0" }}>
                        <span>{t('HomePageConcept.LeftSide.discover_best_tech_talent', { defaultValue: "Discover best tech talent" })}</span>
                        <span style={{ color: "#37374b" }}>{` `}</span>
                      </p>
                      <p style={{ margin: "0", color: "#423fd3" }}>
                      <span style={{ color: "#423fd3" }}>{t('HomePageConcept.LeftSide.faster', { defaultValue: "faster" })}</span>
                      <span style={{ color: "#000" }}>{` `}</span>
                      <span style={{ color: "#37374b" }}>{t('HomePageConcept.LeftSide.and', { defaultValue: "and" })}</span>
                      <span style={{ color: "#000" }}>{` `}</span>
                      <span>{t('HomePageConcept.LeftSide.smarter', { defaultValue: "smarter" })}</span>
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      position: "relative",
                      fontSize: "0.9rem",
                      letterSpacing: "-0.02em",
                      lineHeight: "1.5",
                      color: "#37374b",
                      display: "inline-block",
                      textAlign: "left",
                    }}
                  >
                    <p style={{ margin: "0" }}>
                    {t('HomePageConcept.LeftSide.description_opt2', {
                        defaultValue: "Use SkillQ's Gen AI-powered voice agent to efficiently screen tech candidates,"
                      })}
                      <br />
                    {t('HomePageConcept.LeftSide.description_opt21', {
                        defaultValue: "detect cheating, and deliver an exceptional candidate experience"
                      })}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0px 71px 0px 0px",
                    boxSizing: "border-box",
                    gap: "16px",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    fontSize: "16px",
                    color: "#000",
                  }}
                >
                  <button
                    style={{
                      cursor: "pointer",
                      border: "none",
                      padding: "12px 24px",
                      backgroundColor: "#423fd3",
                      borderRadius: "100px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "8px",
                      whiteSpace: "nowrap",
                    }}
                    onClick={handleRedirect}
                  >
                    <div
                      style={{
                        position: "relative",
                        fontSize: "16px",
                        letterSpacing: "-0.01em",
                        lineHeight: "28px",
                        fontWeight: "500",
                        color: "#fff",
                      }}
                      >
                      {t('HomePageConcept.LeftSide.get_started', { defaultValue: "Get started with SkillQ for free" })}
                    </div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/landing/Vector.webp`}
                      alt="Vector"
                      style={{
                        margin: "0",
                        height: "0.75rem",
                        width: "0.75rem",
                        position: "relative",
                        overflow: "hidden",
                        flexShrink: "0",
                      }}
                    />
                  </button>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "21px",
                      minWidth: "217px",
                    }}
                    className="action-links"
                  >
                    <div
                      style={{
                        height: "78px",
                        width: "78px",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={handleVideoOpen}
                    >
                      <img
                        style={{
                          height: "120%",
                          width: "120%",
                          objectFit: "contain",
                          position: "absolute",
                          left: "0px",
                          top: "0px",
                          transform: "scale(1.641)",
                          cursor: "pointer",
                        }}
                        loading="lazy"
                        alt=""
                        src={`${process.env.PUBLIC_URL}/images/landing/Group 9270.webp`}
                      />
                    </div>
                    <div
                      style={{
                        flex: "1",
                        borderRadius: "12px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: "12px 0px",
                        boxSizing: "border-box",
                        minWidth: "149px",
                      }}
                      onClick={handleVideoOpen}
                    >
                      <a
                        style={{
                          textDecoration: "none",
                          position: "relative",
                          letterSpacing: "-0.01em",
                          lineHeight: "28px",
                          fontWeight: "500",
                          color: "inherit",
                          cursor: "pointer",
                        }}
                        >
                        {t('HomePageConcept.LeftSide.see_action', { defaultValue: "See SkillQ in action" })}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: "2vw",
                fontSize: "1.5rem",
                color: "#0b0b24",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "1.5vh",
                  marginTop: "3vh",
                }}
              >
                <img
                  style={{
                    width: "7vw",
                    height: "auto",
                    zIndex: "1",
                  }}
                  loading="lazy"
                  alt=""
                  src={`${process.env.PUBLIC_URL}/images/landing/Avatars.webp`}
                  className="responsive-image"
                />
                <h3
                  style={{
                    margin: "0",
                    fontSize: "inherit",
                    lineHeight: "1.75",
                    fontWeight: "400",
                    zIndex: "1",
                  }}
                >
                  {t('HomePageConcept.LeftSide.candidates_satisfied', { defaultValue: "+150 Candidates satisfied" })}
                  </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={showVideo}
        onClose={handleVideoClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{
          position: 'relative',
          width: isMobile ? '90%' : '70%',
          maxWidth: isMobile ? '800px' : '1200px',
          aspectRatio: '16/9'
        }}>
          <FullScreenVideo videoUrl="https://youtu.be/UcJcS06NDbE" onClose={handleVideoClose} />
        </Box>
      </Modal>
      <style jsx>{`
        @media (max-width: 768px) {
          .action-links {
            flex-direction: column;
            gap: 10px;
          }
        }
      `}</style>
    </div>
  );
};

export default LeftSide;
