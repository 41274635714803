import { analytics } from '../../../shared/firebase/firebase'; // Ensure the path is correct
import { logEvent } from 'firebase/analytics';

import React, { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const packages = [
  {
    title: "Intro Package",
    interviews: "10 INTERVIEWS",
    pricePerInterview: "5$/each",
    totalPrice: "50$",
    tooltip: "With this plan you can create up to 2 AI voice agents customised to the jobs you are hiring for. In other words, hiring can be scaled for 2 job Vacancies"
  },
  {
    title: "Standard Package",
    subtitle: "Most popular",
    interviews: "50 INTERVIEWS",
    pricePerInterview: "4$/each",
    totalPrice: "200$",
    tooltip: "With this plan you can create up to 10 AI voice agents customised to the jobs you are hiring for. In other words, hiring can be scaled for 10 job Vacancies"
  },
  {
    title: "Ultimate Package",
    interviews: "100 INTERVIEWS",
    pricePerInterview: "3$/each",
    totalPrice: "300$",
    tooltip: "With this plan you can create up to 20 AI voice agents customised to the jobs you are hiring for. In other words, hiring can be scaled for 20 job Vacancies"
  }
];

const Pricing = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [tooltip, setTooltip] = useState("");
  const navigate = useNavigate();

  const logAnalyticsEvent = (eventName) => {
    logEvent(analytics, `loggedout_Pricing_Pricing_${eventName}`);
  };

  const handleMouseEnter = (text) => {
    setTooltip(text);
    logAnalyticsEvent('tooltip_mouse_enter');
  };

  const handleMouseLeave = () => {
    setTooltip("");
    logAnalyticsEvent('tooltip_mouse_leave');
  };

  const handleGetStartedClick = (packageTitle) => {
    logAnalyticsEvent(`get_started_click_${packageTitle}`);
    navigate('/register/company');
  };

  return (
    <div style={{ padding: isMobile ? '10px' : '20px', textAlign: 'center', fontFamily: 'Poppins', marginTop: '40px', marginBottom: '100px' }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          lineHeight: "normal",
          letterSpacing: "normal",
          textAlign: "center",
          fontSize: isMobile ? '32px' : '40px',
          color: "#0b0b24",
          fontFamily: "Poppins",
          marginBottom: '20px'
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "0px 0px 0px 0px",
              boxSizing: "border-box",
              maxWidth: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                position: "relative",
                letterSpacing: "-0.02em",
                lineHeight: "40px",
                fontWeight: "500",
                display: "inline-block",
                maxWidth: "104%",
                flexShrink: "0",
              }}
            >
              <span>{`Pricing built for businesses of `}</span>
              <span style={{ color: "#423fd3" }}>all sizes</span>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          lineHeight: "normal",
          letterSpacing: "normal",
          textAlign: "center",
          fontSize: isMobile ? '24px' : '32px',
          color: "#0b0b24",
          fontFamily: "Poppins",
          marginBottom: '10vh'
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "0px 0px 0px 0px",
              boxSizing: "border-box",
              maxWidth: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                position: "relative",
                letterSpacing: "-0.02em",
                lineHeight: "32px",
                display: "inline-block",
                maxWidth: "100%",
                flexShrink: "0",
              }}
            >
              Choose your package
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'center', alignItems: 'center', gap: '20px', margin: isMobile ? '0 auto' : '20px 0' }}>
        {packages.map((pkg, index) => (
          <div key={index} style={{
            border: '1px solid #ECECFB',
            borderRadius: '8px',
            padding: '20px',
            width: isMobile ? '90%' : '300px',
            position: 'relative',
            backgroundColor: '#F8F8FF',
            margin: isMobile ? '10px auto' : '0'
          }}>
            {pkg.subtitle && <div style={{ position: 'absolute', top: '-10px', left: '50%', transform: 'translateX(-50%)', backgroundColor: '#423FD3', color: '#FFF', borderRadius: '4px', padding: '4px 8px', fontSize: '12px' }}>{pkg.subtitle}</div>}
            <h3 style={{ fontSize: '20px', margin: '10px 0' }}>{pkg.title}</h3>
            <p style={{ fontSize: '16px', margin: '10px 0' }}>{pkg.interviews} <span style={{ position: 'relative', display: 'inline-block' }} onMouseEnter={() => handleMouseEnter(pkg.tooltip)} onMouseLeave={handleMouseLeave}><span style={{ display: 'inline-block', width: '20px', height: '20px', borderRadius: '50%', backgroundColor: 'transparent', border: '1px solid #423FD3', color: '#423FD3', textAlign: 'center', lineHeight: '20px', cursor: 'pointer' }}>i</span><span style={{ visibility: tooltip === pkg.tooltip ? 'visible' : 'hidden', backgroundColor: 'black', color: '#fff', textAlign: 'center', borderRadius: '6px', padding: '5px', position: 'absolute', zIndex: '1', bottom: '125%', left: '50%', transform: 'translateX(-50%)', fontSize: '12px', whiteSpace: 'normal', width: '200px' }}>{pkg.tooltip}</span></span></p>
            <p style={{ fontSize: '16px', margin: '10px 0' }}>{pkg.pricePerInterview}</p>
            <p style={{ fontSize: '24px', fontWeight: 'bold', margin: '10px 0' }}>{pkg.totalPrice}</p>
            <button 
              onClick={() => handleGetStartedClick(pkg.title)}
              style={{
                backgroundColor: '#423FD3',
                color: '#FFF',
                border: 'none',
                borderRadius: '4px',
                padding: '10px 20px',
                cursor: 'pointer'
              }}>
              Get started
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
