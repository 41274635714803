import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { Routes, Route, useLocation } from "react-router-dom";
import ContactForm from "./HR/ContactForm";
import CompanySignup from "./signup/CompanySignup/CompanySignup";
import UserSignup from "./signup/UserSignup/UserSignup";
import Login from "./login/Login";
import NavBar from "./navigation/NavBar";
import Footer from "./footer/Footer";
import PrivacyPolicy from "./policies/PrivacyPolicy";
import TermsOfService from "./policies/TermsOfService";
import HomePageConcept from "./HomePage/HomePageConcept";
import HomePageConceptTech from "./HomePageTech/HomePageConcept";
import PricingMain from "./pricing/PricingMain";
import ArticleList from "./articles/ArticleList"; // Import ArticleList
import Article from "./articles/Article";         // Import Article

const NAV_HEIGHT = 100; // Constant for navheight

function Routing(props) {
  const {
    blogPosts,
    selectBlog,
    selectHome,
    selectedTab,
    setSelectedTab,
    mobileDrawerOpen,
    handleMobileDrawerOpen,
    handleMobileDrawerClose,
    navheight
  } = props;

  const location = useLocation();
  const pathname = location.pathname;
  const hideNavBarAndFooter = [
    "/register/company",
    "/register/user",
    "/privacy-policy",
    "/terms-of-service",
    "/",
    "/pricing",
    "/home-page-tech",
  ].includes(pathname);

  useEffect(() => {
    console.log(`Current pathname: ${pathname}`);
    console.log(`Hide NavBar and Footer: ${hideNavBarAndFooter}`);
    if (hideNavBarAndFooter) {
      console.log("NavBar and Footer are hidden");
    } else {
      console.log("NavBar and Footer are shown");
    }
  }, [pathname, hideNavBarAndFooter]);

  // Log route changes
  useEffect(() => {
    console.log(`Navigated to route: ${pathname}`);
  }, [pathname]);

  return (
    <>
      {!hideNavBarAndFooter && (
        <NavBar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          mobileDrawerOpen={mobileDrawerOpen}
          handleMobileDrawerOpen={handleMobileDrawerOpen}
          handleMobileDrawerClose={handleMobileDrawerClose}
          navheight={navheight}
        />
      )}
      <Routes>
        <Route
          exact
          path="/ContactForm"
          element={<ContactForm navheight={navheight}/>}
        />
        <Route
          exact
          path="/register/company"
          element={<CompanySignup />}
        />
        <Route
          exact
          path="/register/user"
          element={<UserSignup />}
        />
        <Route
          exact
          path="/login"
          element={<Login />}
        />
        <Route
          exact
          path="/terms-of-service"
          element={<TermsOfService />}
        />
        <Route 
          exact
          path="/privacy-policy"
          element={<PrivacyPolicy />} 
        />
        <Route 
          exact
          path="/"
          element={<HomePageConcept />} 
        />
        <Route 
          exact
          path="/Pricing"
          element={<PricingMain />} 
        />
        <Route 
          exact
          path="/home-page-tech"
          element={<HomePageConceptTech />} 
        />
        
        {/* Routes for Articles */}
        <Route
          exact
          path="/blog"
          element={<ArticleList />}
        />
        <Route
          exact
          path="/blog/:articleName"
          element={<Article />}
        />

      </Routes>
      {!hideNavBarAndFooter && <Footer />}
    </>
  );
}

Routing.propTypes = {
  blogPosts: PropTypes.arrayOf(PropTypes.object),
  selectHome: PropTypes.func.isRequired,
  selectBlog: PropTypes.func.isRequired,
  selectedTab: PropTypes.string,
  setSelectedTab: PropTypes.func.isRequired,
  mobileDrawerOpen: PropTypes.bool.isRequired,
  handleMobileDrawerOpen: PropTypes.func.isRequired,
  handleMobileDrawerClose: PropTypes.func.isRequired,
  navheight: PropTypes.number.isRequired, // Added navheight prop type
};

export default memo(Routing);
