import { analytics } from '../../../../../shared/firebase/firebase'; // Ensure the path is correct
import { logEvent } from 'firebase/analytics';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";  // Import i18n hook
import PropTypes from 'prop-types';
import { Modal, Box, useMediaQuery } from '@mui/material';
import FullScreenVideo from './FullScreenVideo';
import { useNavigate } from 'react-router-dom';

const LeftSide = () => {
  const { t } = useTranslation();  // Initialize translation hook
  const [showVideo, setShowVideo] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();

  const logAnalyticsEvent = (eventName) => {
    logEvent(analytics, `loggedout_homepage_MobileMainScreen_${eventName}`);
  };

  const handleVideoOpen = () => {
    setShowVideo(true);
    logAnalyticsEvent('video_open');
  };

  const handleVideoClose = () => {
    setShowVideo(false);
    logAnalyticsEvent('video_close');
  };

  const handleRedirect = () => {
    navigate('/register/company');
    logAnalyticsEvent('get_started_click');
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "4vh 3vw 0vh 3vw",
        boxSizing: "border-box",
        position: "relative",
        width: "100%",
        zIndex: "2",
        fontFamily: "Poppins",
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
          textAlign: "left",
          fontSize: "14px",
          color: "#423fd3",
          fontFamily: "Poppins",
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "20px",
          }}
        >
          <div
            style={{
              borderRadius: "70px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              border: "1px solid #ececfb",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "6px 14px 6px 15px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <h1
                style={{
                  margin: "0",
                  height: "21px",
                  position: "relative",
                  fontSize: "inherit",
                  letterSpacing: "0.02em",
                  textTransform: "uppercase",
                  fontWeight: "600",
                  fontFamily: "inherit",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t('HomePageConcept.LeftSide.smart_ai_agent', { defaultValue: "Smart AI voice agent" })}
              </h1>
            </div>
          </div>
          <h1
            style={{
              margin: "0",
              alignSelf: "stretch",
              position: "relative",
              fontSize: "32px",
              letterSpacing: "-0.02em",
              lineHeight: "40px",
              fontWeight: "500",
              fontFamily: "inherit",
              textAlign: "center",
              color: "#37374b",
            }}
          >
            <span style={{ color: "#0b0b24" }}>
              {t('HomePageConcept.LeftSide.discover_best_tech_talent', { defaultValue: "Discover best tech talent" })}
            </span>
            <span>{` `}</span>
            <span style={{ color: "#423fd3" }}>
              {t('HomePageConcept.LeftSide.faster', { defaultValue: "faster" })}
            </span>
            <span style={{ color: "#000" }}>{` `}</span>
            <span>{t('HomePageConcept.LeftSide.and', { defaultValue: "and" })}</span>
            <span style={{ color: "#000" }}>{` `}</span>
            <span style={{ color: "#423fd3" }}>
              {t('HomePageConcept.LeftSide.smarter', { defaultValue: "smarter" })}
            </span>
          </h1>
        </div>
        <h1
          style={{
            margin: "0",
            position: "relative",
            fontSize: "inherit",
            letterSpacing: "-0.01em",
            lineHeight: "24px",
            fontWeight: "400",
            fontFamily: "inherit",
            color: "#37374b",
            textAlign: "center",
          }}
        >
          <p style={{ margin: "0" }}>
            {t('HomePageConcept.LeftSide.description_opt2', { defaultValue: "Use SkillQ's Gen AI-powered voice agent to efficiently screen tech candidates, detect cheating, and deliver an exceptional candidate experience" })}
          </p>
        </h1>
        <div
          style={{
            alignSelf: "stretch",
            height: "162px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 15px",
            boxSizing: "border-box",
            gap: "32px",
            textAlign: "left",
            fontSize: "16px",
            color: "#000",
            fontFamily: "Poppins",
          }}
        >
          <button
            style={{
              cursor: "pointer",
              border: "none",
              padding: "12px 24px",
              backgroundColor: "#423fd3",
              borderRadius: "100px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              whiteSpace: "nowrap",
            }}
            onClick={handleRedirect}
          >
            <div
              style={{
                position: "relative",
                fontSize: "16px",
                letterSpacing: "-0.01em",
                lineHeight: "28px",
                fontWeight: "500",
                color: "#fff",
              }}
            >
              {t('HomePageConcept.LeftSide.get_started', { defaultValue: "Get started with SkillQ for free" })}
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/images/landing/Vector.webp`}
              alt="Vector"
              style={{
                margin: "0",
                height: "0.75rem",
                width: "0.75rem",
                position: "relative",
                overflow: "hidden",
                flexShrink: "0",
              }}
            />
          </button>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px 10px",
              gap: "21px",
            }}
            onClick={handleVideoOpen}
          >
            <div
              style={{
                height: "78px",
                width: "78px",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                  position: "absolute",
                  left: "0px",
                  top: "8px",
                  transform: "scale(1.641)",
                }}
                loading="lazy"
                alt=""
                src={`${process.env.PUBLIC_URL}/images/landing/Group 9270.webp`}
              />
            </div>
            <div
              style={{
                borderRadius: "12px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "12px 0px",
              }}
            >
              <h1
                style={{
                  margin: "0",
                  position: "relative",
                  fontSize: "inherit",
                  letterSpacing: "-0.01em",
                  lineHeight: "28px",
                  fontWeight: "500",
                  fontFamily: "inherit",
                }}
              >
                {t('HomePageConcept.LeftSide.see_action', { defaultValue: "See SkillQ in action" })}
              </h1>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "2vw",
            fontSize: "1.5rem",
            color: "#0b0b24",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1.5vh",
              marginTop: "3vh",
            }}
          >
            <img
              style={{
                height: "44px",
                width: "110px",
                height: "auto",
                zIndex: "1",
              }}
              loading="lazy"
              alt=""
              src={`${process.env.PUBLIC_URL}/images/landing/Avatars.webp`}
              className="responsive-image"
            />
            <h3
              style={{
                margin: "0",
                fontSize: "1.2rem",
                lineHeight: "1.75",
                fontWeight: "400",
                zIndex: "1",
              }}
            >
              {t('HomePageConcept.LeftSide.candidates_satisfied', { defaultValue: "+150 Candidates satisfied" })}
            </h3>
          </div>
        </div>
      </div>
      <Modal
        open={showVideo}
        onClose={handleVideoClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{
          position: 'relative',
          width: isMobile ? '90%' : '70%',
          maxWidth: isMobile ? '800px' : '1200px',
          aspectRatio: '16/9'
        }}>
          <FullScreenVideo videoUrl="https://youtu.be/UcJcS06NDbE" onClose={handleVideoClose} />
        </Box>
      </Modal>
      <style jsx>{`
        @media (max-width: 768px) {
          .action-links {
            flex-direction: column;
            gap: 10px;
          }
        }
      `}</style>
    </div>
  );
};

export default LeftSide;
