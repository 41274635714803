import React from 'react';

const RightSide = () => {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',  // Align the content to the top
        width: '100%',
        height: '100vh',
        boxSizing: 'border-box',
        padding: '1vh 1vw',
        overflow: 'hidden',
        marginTop: "8vh",
    };

    const imageStyle = {
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
        maxWidth: '90vw',
        maxHeight: '90vh',
    };

    return (
        <div style={containerStyle}>
            <img
                src={`${process.env.PUBLIC_URL}/images/landing/Herp-img.webp`}
                alt="Right part"
                style={imageStyle}
            />
        </div>
    );
};

export default RightSide;
