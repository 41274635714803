// SplitScreenImage.js

import React, { useRef, useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const SplitScreenImage = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState('');

  const imageSrc = `${process.env.PUBLIC_URL}/images/landing/Group 9396.webp`;
  const videoPath = 'public/Alex_chat_bot_recording.mp4';

  const handleImageClick = () => {
    setShowVideo(true);
    setIsLoading(true);
  };

  useEffect(() => {
    // Fetch the video URL from Firebase Storage
    const fetchVideo = async () => {
      const storage = getStorage();
      const videoRef = ref(storage, videoPath);

      try {
        const url = await getDownloadURL(videoRef);
        setVideoUrl(url);
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to load video', error);
      }
    };

    if (showVideo) {
      fetchVideo();
    }
  }, [showVideo, videoPath]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener('ended', () => {
        // Video has ended, handle any logic if needed
      });

      return () => {
        videoElement.removeEventListener('ended', () => {
          // Cleanup
        });
      };
    }
  }, [videoUrl]);

  const mediaStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    maxHeight: "90%",
    maxWidth: "90%",
  };

  return (
    <div
      style={{
        position: "relative",
        height: "30vh",
        width: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          height: "50%",
          backgroundColor: "#1c1a59",
        }}
      />
      <div
        style={{
          position: "absolute",
          bottom: "0",
          left: "0",
          right: "0",
          height: "50%",
          backgroundColor: "#fff",
        }}
      />
      {showVideo ? (
        isLoading ? (
          <div
            style={{
              ...mediaStyle,
              backgroundColor: "black",
            }}
          />
        ) : (
          <video
            ref={videoRef}
            src={videoUrl}
            controls
            autoPlay
            style={mediaStyle}
          />
        )
      ) : (
        <img
          src={imageSrc}
          alt="Split screen image"
          style={{ ...mediaStyle, cursor: "pointer" }}
          onClick={handleImageClick}
        />
      )}
    </div>
  );
};

export default SplitScreenImage;