import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { analytics } from '../../../shared/firebase/firebase';
import { logEvent } from 'firebase/analytics';

const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: '#F9FAFB',
  textAlign: 'center',
  minHeight: '120vh',
}));

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  width: '100%',
  maxWidth: '500px',
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5, 4),
  fontSize: '1rem',
  fontWeight: '600',
  textTransform: 'none',
  backgroundColor: theme.palette.primary.main,
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const OuterContainer = styled(Box)(({ theme, navheight }) => ({
  padding: theme.spacing(7),
  marginTop: navheight > 0 ? theme.spacing(10) : 0,  // Adjusting the top margin
}));

const ContactForm = ({ onClick = () => {} }) => { // Default onClick handler
  const { t } = useTranslation(); // Initialize translation hook
  const [formData, setFormData] = useState({
    user_name: '',
    company_name: '',
    user_email: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [navheight, setNavHeight] = useState(0);
  const navRef = useRef(null);

  useEffect(() => {
    if (navRef.current) {
      const height = navRef.current.offsetHeight;
      setNavHeight(height);
      console.log('Initial navheight:', height);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (navRef.current) {
        const height = navRef.current.offsetHeight;
        setNavHeight(height);
        console.log('Updated navheight on resize:', height);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    console.log('navheight state:', navheight);
  }, [navheight]);

  const handleButtonClick = (action, label) => {
    onClick(action, label);
    logEvent(analytics, 'loggedout_ContactForm_SendRequest'); // Log the analytics event
  };

  return (
    <div ref={navRef} onClick={() => handleButtonClick('contact_form', 'Contact Form Interaction')}>
      <OuterContainer navheight={navheight}>
        <Container>
          <Typography variant="h4" gutterBottom>
            {t('ContactForm.form_title', { defaultValue: 'Contact Us' })}
          </Typography>
          <Form
            action="https://formspree.io/f/xknddbgl"
            method="POST"
            onSubmit={() => handleButtonClick('form_submit', 'Contact Form Submission')}
          >
            <TextFieldStyled
              name="user_name"
              label={t('ContactForm.user_name', { defaultValue: 'Name' })}
              variant="outlined"
              value={formData.user_name}
              onChange={handleChange}
              required
            />
            <TextFieldStyled
              name="company_name"
              label={t('ContactForm.company_name', { defaultValue: 'Company Name' })}
              variant="outlined"
              value={formData.company_name}
              onChange={handleChange}
              required
            />
            <TextFieldStyled
              name="user_email"
              label={t('ContactForm.user_email', { defaultValue: 'Email' })}
              type="email"
              variant="outlined"
              value={formData.user_email}
              onChange={handleChange}
              required
            />
            <ButtonStyled type="submit">
              {t('ContactForm.send_request', { defaultValue: 'Send Request' })}
            </ButtonStyled>
          </Form>
        </Container>
      </OuterContainer>
    </div>
  );
};

ContactForm.propTypes = {
  onClick: PropTypes.func, // Add prop type for onClick
};

export default ContactForm;
