import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Button, Hidden, IconButton, Box, List, ListItem, ListItemText, Drawer, Divider } from "@mui/material";
import { styled, useTheme } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from '@mui/material/useMediaQuery';
import { analytics } from '../../../shared/firebase/firebase';
import { logEvent } from 'firebase/analytics';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(1, 0),
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: "100px",
  padding: theme.spacing(0, 10),
}));

const LogoLink = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  marginLeft: theme.spacing(2),
}));

const BrandImage = styled("img")(({ theme }) => ({
  width: "120px",
  marginRight: theme.spacing(1),
}));

const NavLinks = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
});

const NavLink = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(4),
  textDecoration: "none",
  fontSize: "1.0rem",
  color: "#4B5563",
  textTransform: "none",
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const ActiveNavLink = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const LoginButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#FFFFFF",
  padding: theme.spacing(1, 3),
  textTransform: "none",
  fontSize: "1.1rem",
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  marginRight: theme.spacing(8),
  marginLeft: theme.spacing(8),
}));

const ContactUsButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontSize: "1.1rem",
  color: "#4B5563",
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const NoDecorationLink = styled(Link)({
  textDecoration: "none !important",
});

const DrawerList = styled(List)(({ theme }) => ({
  width: 250,
}));

const NavBar = (props) => {
  const {
    handleMobileDrawerOpen,
    handleMobileDrawerClose,
    mobileDrawerOpen,
    selectedTab,
    setSelectedTab,
    navheight // Accept navheight prop
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const handleClick = (event) => {
      logEvent(analytics, 'click', {
        category: 'user_interaction',
        label: event.target.tagName,
        action: 'click',
        value: event.target.outerHTML
      });
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const menuItems = [
    {
      link: "/",
      name: "For Companies",
    },
    {
      link: "/pricing",
      name: "Pricing",
    },
    {
      link: "/ContactForm",
      name: "Book A Demo",
    },
    {
      link: "/login",
      name: "Log in",
    },
  ];

  const drawer = (
    <DrawerList>
      <ListItem button onClick={handleMobileDrawerClose}>
        <IconButton edge="end">
          <CloseIcon />
        </IconButton>
      </ListItem>
      <Divider />
      {menuItems.map((item) => (
        <NoDecorationLink key={item.name} to={item.link}>
          <ListItem button onClick={handleMobileDrawerClose}>
            <ListItemText primary={item.name} />
          </ListItem>
        </NoDecorationLink>
      ))}
    </DrawerList>
  );

  return (
    <Box>
      <StyledAppBar position="fixed">
        <StyledToolbar style={{ minHeight: navheight }}>
          <LogoLink to="/">
            <BrandImage
              src={`${process.env.PUBLIC_URL}/skillQ_logo.png`}
              alt="SkillQ"
            />
          </LogoLink>
          <NavLinks>
            <Hidden mdUp>
              <IconButton
                onClick={handleMobileDrawerOpen}
                aria-label="Open Navigation"
                size="large">
                <MenuIcon color="primary" />
              </IconButton>
            </Hidden>
          </NavLinks>
          <Box>
            <Hidden mdDown>
              <NoDecorationLink to="/pricing">
                <NavLink>
                  Pricing
                </NavLink>
              </NoDecorationLink>
              <NoDecorationLink to="/ContactForm">
                <ContactUsButton color="inherit">
                    Book a demo
                </ContactUsButton>
              </NoDecorationLink>
              <NoDecorationLink to="/login">
                <LoginButton>
                  Log in
                </LoginButton>
              </NoDecorationLink>
            </Hidden>
          </Box>
        </StyledToolbar>
      </StyledAppBar>
      <Drawer
        anchor="right"
        open={mobileDrawerOpen}
        onClose={handleMobileDrawerClose}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

NavBar.propTypes = {
  handleMobileDrawerOpen: PropTypes.func,
  handleMobileDrawerClose: PropTypes.func,
  mobileDrawerOpen: PropTypes.bool,
  selectedTab: PropTypes.string,
  setSelectedTab: PropTypes.func.isRequired,
  navheight: PropTypes.number.isRequired, // Added prop type for navheight
};

export default memo(NavBar);
