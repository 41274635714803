import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification, signOut, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../../shared/firebase/firebase';
import { useStore } from '../../../../StoreProvider'; // Import useStore from MobX
import { runInAction } from 'mobx';
import { Typography, Box, Paper, TextField, Button, FormControlLabel, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Import i18n hook

const UserSignup = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);
  const [error, setError] = useState('');
  const [showBanner, setShowBanner] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [verificationInterval, setVerificationInterval] = useState(null);
  const navigate = useNavigate();
  const store = useStore(); // Use MobX store

  const canSubmit = () => {
    return email.length > 0 && password.length > 0 && name.length > 0 && isAgreed;
  };

  function getErrorMessage(errorCode) {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return t('SignupPage.UserSignupPage.errors.email_in_use', { defaultValue: 'The email address is already in use by another account.' });
      case 'auth/invalid-email':
        return t('SignupPage.UserSignupPage.errors.invalid_email', { defaultValue: 'The email address is invalid.' });
      case 'auth/operation-not-allowed':
        return t('SignupPage.UserSignupPage.errors.operation_not_allowed', { defaultValue: 'The operation is not allowed.' });
      case 'auth/weak-password':
        return t('SignupPage.UserSignupPage.errors.weak_password', { defaultValue: 'The password is weak.' });
      default:
        return t('SignupPage.UserSignupPage.errors.unknown_error', { defaultValue: 'An unknown error occurred.' });
    }
  }

  const fetchAccountType = async (authUser) => {
    const db = getFirestore();
    const userDoc = await getDoc(doc(db, "users", authUser.uid));
    return userDoc.data()?.accountType || 'user';
  };

  const checkEmailVerification = async (authUser) => {
    await authUser.reload();
    if (authUser.emailVerified) {
      setIsVerified(true);
      clearInterval(verificationInterval);
      setShowBanner(false);

      // Sign the user in after email verification
      await signInWithEmailAndPassword(auth, email, password);

      // Fetch accountType from Firestore
      const accountType = await fetchAccountType(authUser);

      // Set the user details in the MobX store
      runInAction(() => {
        store.user = authUser;
        store.accountType = accountType;
      });

      // Redirect to the /u/interview-preparation path
      navigate('/u/interview-preparation');
    }
  };

  const createAccountWithEmail = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);

      const db = getFirestore();
      await setDoc(doc(db, "users", userCredential.user.uid), {
        name: name,
        email: email,
        accountType: "user"
      });

      await signOut(auth);

      setShowBanner(true);

      // Check every 3.5 seconds for 2 minutes if the user has verified their email
      const intervalId = setInterval(async () => {
        await checkEmailVerification(userCredential.user);
      }, 3500);

      setVerificationInterval(intervalId);

      setTimeout(() => {
        clearInterval(intervalId);
        if (!isVerified) {
          setError(t('SignupPage.UserSignupPage.errors.email_verification_timeout', { defaultValue: 'Email verification timed out. Please check your email and try again.' }));
        }
      }, 120000); // 2 minutes

    } catch (error) {
      const errorMessage = getErrorMessage(error.code);
      setError(errorMessage);
    }
  };

  useEffect(() => {
    return () => {
      if (verificationInterval) {
        clearInterval(verificationInterval);
      }
    };
  }, [verificationInterval]);

  const openInNewTab = (url) => {
    const newTab = window.open(url, '_blank', 'noopener,noreferrer');
    if (newTab) newTab.opener = null;
  };

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0, p: 0, width: '100vw', overflow: 'hidden' }}>
      <Paper elevation={6} sx={{ padding: 4, textAlign: 'center', maxWidth: '400px', width: '100%', m: 0, boxShadow: { xs: 'none', sm: 6 } }}>
        <Typography variant="h4" gutterBottom>
          {t('SignupPage.UserSignupPage.signup_title', { defaultValue: 'Welcome' })} <span role="img" aria-label="wave">👋</span>
        </Typography>
        <form onSubmit={(e) => e.preventDefault()}>
          <TextField
            fullWidth
            label={t('SignupPage.UserSignupPage.name_label', { defaultValue: 'Name and Surname' })}
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label={t('SignupPage.UserSignupPage.email_label', { defaultValue: 'Email' })}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label={t('SignupPage.UserSignupPage.password_label', { defaultValue: 'Password' })}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isAgreed}
                onChange={(e) => setIsAgreed(e.target.checked)}
                name="agreement"
                color="primary"
              />
            }
            label={
              <>
                {t('SignupPage.UserSignupPage.agreement_text', { defaultValue: 'I have read and accept ' })}
                <a href="#" onClick={() => openInNewTab('/terms-of-service')} rel="noopener noreferrer">
                  {t('SignupPage.UserSignupPage.terms_of_service', { defaultValue: 'Terms of Service' })}
                </a>{" "}
                {t('SignupPage.UserSignupPage.and', { defaultValue: 'and ' })}
                <a href="#" onClick={() => openInNewTab('/privacy-policy')} rel="noopener noreferrer">
                  {t('SignupPage.UserSignupPage.privacy_policy', { defaultValue: 'Privacy Policy' })}
                </a>
              </>
            }
          />
          {error && (
            <Typography color="error" sx={{ marginBottom: 2 }}>
              {error}
            </Typography>
          )}
          <Box sx={{ marginTop: 2, textAlign: 'center' }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={createAccountWithEmail}
              disabled={!canSubmit()}
            >
              {t('SignupPage.UserSignupPage.register_button', { defaultValue: 'Register' })}
            </Button>
          </Box>
          {showBanner && (
            <Typography color="primary" sx={{ marginTop: 2 }}>
              {t('SignupPage.UserSignupPage.check_email_banner', { defaultValue: 'Check your email for a confirmation message' })}
            </Typography>
          )}
          {isVerified && (
            <Typography color="primary" sx={{ marginTop: 2 }}>
              {t('SignupPage.UserSignupPage.email_verified_message', { defaultValue: 'Your email has been verified successfully! You can now sign in.' })}
            </Typography>
          )}
        </form>
        <Box sx={{ marginTop: 4, textAlign: 'center' }}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => navigate('/login')}
          >
            {t('SignupPage.UserSignupPage.already_registered', { defaultValue: 'Already registered? Log in' })}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default UserSignup;
