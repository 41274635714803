import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { autorun } from 'mobx';
import userStore from './store'; // Adjust the import path as needed

// Import your translation files
import translationEN from './locales/en/translation.json';
import translationPT from './locales/pt/translation.json';
import translationFR from './locales/fr/translation.json';
import translationSP from './locales/sp/translation.json';
import translationRU from './locales/ru/translation.json';

// Initialize i18n
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      pt: { translation: translationPT },
      fr: { translation: translationFR },
      sp: { translation: translationSP },
      ru: { translation: translationRU },
    },
    lng: userStore.language, // Use the language from userStore
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
  });

// React to changes in userStore.language
autorun(() => {
  i18n.changeLanguage(userStore.language);
});

export default i18n;
