import React from "react";
import TopComponent from "./subcomponents_2/TopComponent";
import BottomComponent from "./subcomponents_2/BottomComponent";

const MainComponent = () => {
  return (
    <div
      style={{
        width: "100%",
        margin: "0",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "20px 0",
        boxSizing: "border-box",
        zIndex: "4",
        textAlign: "center",
        fontSize: "23px",
        color: "#0b0b24",
        fontFamily: "Poppins",
      }}
    >
      <TopComponent />
      <BottomComponent />
    </div>
  );
};

export default MainComponent;
