import PropTypes from "prop-types";
import { useTranslation } from "react-i18next"; // Import translation hook

const Footer1 = ({ className = "" }) => {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        backgroundColor: "#1c1a59",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        padding: "60px 16px",
        boxSizing: "border-box",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#fff",
        fontFamily: "Poppins",
      }}
      className={className}
    >
      <section
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "32px",
          maxWidth: "100%",
        }}
      >
        <FooterContent />
        <FooterDivider />
      </section>
    </div>
  );
};

const FooterContent = ({ className = "" }) => {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "32px",
        textAlign: "left",
        fontSize: "16px",
        color: "#fff",
        fontFamily: "Poppins",
      }}
      className={className}
    >
      <img
        style={{
          width: "150px",
          height: "48px",
          objectFit: "cover",
        }}
        loading="lazy"
        alt=""
        src={`${process.env.PUBLIC_URL}/skillQ_logo 2.webp`}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "12px",
          maxWidth: "90%",
        }}
      >
        <div style={{ fontWeight: "500" }}>
          {t("HomePageConcept.Footer.about_company")}
        </div>
        <div style={{ fontSize: "14px", color: "#fafafb" }}>
          {t("HomePageConcept.Footer.company_description")}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "16px",
        }}
      >
        <div
          style={{
            width: "80%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "16px",
          }}
        >
          <div
            style={{
              height: "32px",
              width: "32px",
              borderRadius: "100%",
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                width: "32px",
                height: "32px",
              }}
              alt=""
              src={`${process.env.PUBLIC_URL}/images/landing/ic_round-email.webp`}
            />
          </div>
          <div style={{ fontWeight: "500", whiteSpace: "nowrap" }}>
            info@skillq.org
          </div>
        </div>
        <div
          style={{
            width: "80%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "16px",
          }}
        >
          <div
            style={{
              height: "32px",
              width: "32px",
              borderRadius: "100%",
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                width: "32px",
                height: "32px",
              }}
              alt=""
              src={`${process.env.PUBLIC_URL}/images/landing/ic_round-email (1).webp`}
            />
          </div>
          <div style={{ lineHeight: "20px", fontWeight: "500" }}>
            <p style={{ margin: "0" }}>{t("HomePageConcept.Footer.address_line_1")}</p>
            <p style={{ margin: "0" }}>{t("HomePageConcept.Footer.address_line_2")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const FooterDivider = ({ className = "" }) => {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "32px",
      }}
      className={className}
    >
      <div
        style={{
          width: "80%",
          height: "1px",
          backgroundColor: "#fff",
        }}
      />
      <footer
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "24px",
          fontSize: "14px",
          color: "#fff",
          fontFamily: "Poppins",
        }}
      >
        <div style={{ textAlign: "center" }}>
          © 2024 SkillQ. {t("HomePageConcept.Footer.all_rights_reserved")}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "24px",
          }}
        >
          <a href="https://x.com/skill_q" target="_blank" rel="noopener noreferrer">
            <img
              style={{
                height: "32px",
                width: "32px",
                borderRadius: "100%",
              }}
              loading="lazy"
              alt="Twitter"
              src={`${process.env.PUBLIC_URL}/images/landing/Frame 6765.webp`}
            />
          </a>
          <a href="https://www.youtube.com/channel/UCPCYrZk6b2xFz_rUrO2Y6sg" target="_blank" rel="noopener noreferrer">
            <img
              style={{
                height: "32px",
                width: "32px",
                borderRadius: "100%",
              }}
              loading="lazy"
              alt="YouTube"
              src={`${process.env.PUBLIC_URL}/images/landing/Vector (1).webp`}
            />
          </a>
          <a href="https://www.linkedin.com/company/skill-q/" target="_blank" rel="noopener noreferrer">
            <img
              style={{
                height: "32px",
                width: "32px",
                borderRadius: "100%",
              }}
              loading="lazy"
              alt="LinkedIn"
              src={`${process.env.PUBLIC_URL}/images/landing/Frame 6764.webp`}
            />
          </a>
        </div>
      </footer>
    </div>
  );
};

Footer1.propTypes = {
  className: PropTypes.string,
};

FooterContent.propTypes = {
  className: PropTypes.string,
};

FooterDivider.propTypes = {
  className: PropTypes.string,
};

export default Footer1;
