import { analytics } from '../../../../shared/firebase/firebase'; // Ensure the path is correct
import { logEvent } from 'firebase/analytics';

import React from "react";
import { useTranslation } from "react-i18next";  // Import i18n hook
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const TrySkillQBackground = ({ className = "" }) => {
  const { t } = useTranslation();  // Initialize translation hook
  const navigate = useNavigate();

  const logAnalyticsEvent = (eventName) => {
    logEvent(analytics, `loggedout_homepage_TrySkillqBox_${eventName}`);
  };

  const handleGetStartedClick = () => {
    logAnalyticsEvent('get_started_click');
    navigate('/register/company');
  };

  return (
    <div
      style={{
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "center",
        padding: "0px 20px 0px 21px",
        boxSizing: "border-box",
        maxWidth: "100%",
        textAlign: "center",
        fontSize: "48px",
        color: "#050222",
        fontFamily: "Poppins",
      }}
      className={className}
    >
      <div
        style={{
          width: "1183px",
          borderRadius: "24px",
          backgroundColor: "rgba(236, 236, 251, 0.5)",
          overflow: "hidden",
          flexShrink: "0",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "12px",
          boxSizing: "border-box",
          maxWidth: "100%",
          zIndex: "1",
        }}
      >
        <div
          style={{
            flex: "1",
            borderRadius: "16px",
            backgroundColor: "#fbfbfb",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "center",
            padding: "60px 20px 59px",
            boxSizing: "border-box",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              width: "419px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "40px",
              maxWidth: "100%",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "12px",
              }}
            >
              <h1
                style={{
                  margin: "0",
                  alignSelf: "stretch",
                  height: "62px",
                  position: "relative",
                  fontSize: "inherit",
                  lineHeight: "48px",
                  fontWeight: "600",
                  fontFamily: "inherit",
                  display: "flex",
                  alignItems: "center",
                  flexShrink: "0",
                }}
              >
                <span>
                  <span>{t('HomePageConcept.TrySkillQBackground.try_skillq', { defaultValue: "Try" })}</span>
                  <span style={{ color: "#423fd3" }}>{t('HomePageConcept.TrySkillQBackground.skillq', { defaultValue: " SkillQ" })}</span>
                  <span>{t('HomePageConcept.TrySkillQBackground.now', { defaultValue: " now!" })}</span>
                </span>
              </h1>
              <div
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontSize: "19px",
                  lineHeight: "24px",
                  fontWeight: "500",
                  color: "#6a6a6a",
                }}
              >
                {t('HomePageConcept.TrySkillQBackground.first_3_interviews', { defaultValue: "First 3 interviews are free. Make your high volume hiring more efficient." })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0px 65px",
              }}
            >
              <button
                style={{
                  cursor: "pointer",
                  border: "none",
                  padding: "16px 32px",
                  backgroundColor: "#423fd3",
                  borderRadius: "100px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "12px",
                  whiteSpace: "nowrap",
                }}
                onClick={handleGetStartedClick}
              >
                <div
                  style={{
                    position: "relative",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    color: "#fff",
                    textAlign: "left",
                  }}
                >
                  {t('HomePageConcept.TrySkillQBackground.get_started', { defaultValue: "Get started - it’s free" })}
                </div>
                <img
                  src={`${process.env.PUBLIC_URL}/images/landing/Vector.webp`}
                  alt="Vector"
                  style={{
                    marginTop: "6px",
                    margin: "0",
                    height: "0.7rem",
                    width: "0.7rem",
                    position: "relative",
                    overflow: "hidden",
                    flexShrink: "0",
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TrySkillQBackground.propTypes = {
  className: PropTypes.string,
};

export default TrySkillQBackground;
