import { analytics } from '../../../../shared/firebase/firebase'; // Ensure the path is correct
import { logEvent } from 'firebase/analytics';
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'; // Import the translation hook

const logAnalyticsEvent = (eventName) => {
  logEvent(analytics, `loggedout_homepage_MobileFAQ_${eventName}`);
};

const FAQItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div
      style={{
        width: "100%",
        boxShadow: "0px 6px 24px rgba(223, 230, 236, 0.32)",
        borderRadius: "24px",
        backgroundColor: "#fff",
        border: "1px solid #f7f7f7",
        padding: "22px 23px",
        marginBottom: "4px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <div style={{ flex: "1", fontWeight: "500" }}>{question}</div>
        <button
          style={{
            backgroundColor: "#423FD3",
            borderRadius: "50%",
            border: "none",
            width: "39px",
            height: "39px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="white"
            width="24px"
            height="24px"
          >
            <path d={isOpen ? "M8 12l4-4 4 4" : "M12 16l4-4H8z"} />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div
          style={{
            marginTop: "24px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#979797",
          }}
        >
          {answer}
        </div>
      )}
    </div>
  );
};

FAQItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const FrameComponent = ({ className = "" }) => {
  const { t } = useTranslation(); // Initialize translation hook
  const [openIndex, setOpenIndex] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();

  const faqs = [
    {
      question: t("HomePageConcept.FrameComponent.faq_1_question"),
      answer: t("HomePageConcept.FrameComponent.faq_1_answer"),
    },
    {
      question: t("HomePageConcept.FrameComponent.faq_2_question"),
      answer: t("HomePageConcept.FrameComponent.faq_2_answer"),
    },
    {
      question: t("HomePageConcept.FrameComponent.faq_3_question"),
      answer: t("HomePageConcept.FrameComponent.faq_3_answer"),
    },
    {
      question: t("HomePageConcept.FrameComponent.faq_4_question"),
      answer: t("HomePageConcept.FrameComponent.faq_4_answer"),
    },
  ];

  const handleToggle = (index) => {
    logAnalyticsEvent(`question_${index}_toggle`);
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleShowMore = () => {
    logAnalyticsEvent(showMore ? 'show_less' : 'show_more');
    setShowMore(!showMore);
  };

  const handleContactRedirect = () => {
    logAnalyticsEvent('contact_us_click');
    navigate('/ContactForm');
  };

  const visibleFaqs = showMore ? faqs : faqs.slice(0, 3);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px 16px",
        boxSizing: "border-box",
        fontFamily: "Poppins",
      }}
      className={className}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "24px",
        }}
      >
        <div
          style={{
            borderRadius: "70px",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            border: "1px solid #ececfb",
            padding: "6px 15px",
            textTransform: "uppercase",
            fontWeight: "600",
          }}
        >
          {t("HomePageConcept.FrameComponent.faq_label")}
        </div>
        <h1
          style={{
            margin: "20px 0",
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: "600",
            color: "#17172f",
            textAlign: "center",
          }}
        >
          <span>{t("HomePageConcept.FrameComponent.ask_your")}</span>
          <span style={{ color: "#423FD3" }}>{t("HomePageConcept.FrameComponent.question")}</span>
          <span>{t("HomePageConcept.FrameComponent.get_answered")}</span>
        </h1>
        <div
          style={{
            fontSize: "16px",
            color: "#434356",
            lineHeight: "24px",
            fontWeight: "500",
            textAlign: "center",
            marginBottom: "16px",
          }}
        >
          {t("HomePageConcept.FrameComponent.subtitle")}
        </div>
        <b
          style={{
            fontSize: "16px",
            color: "#423FD3",
          }}
        >
          {t("HomePageConcept.FrameComponent.cant_find")}
        </b>
        <button
          style={{
            cursor: "pointer",
            border: "none",
            padding: "12px 48px",
            backgroundColor: "#423FD3",
            borderRadius: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            marginTop: "20px",
            color: "#fff",
            fontSize: "16px",
            fontWeight: "600",
            textTransform: "uppercase",
          }}
          onClick={handleContactRedirect}
        >
          {t("HomePageConcept.FrameComponent.contact_us")}
          <img
            src={`${process.env.PUBLIC_URL}/images/landing/Vector.webp`}
            alt="Vector"
            style={{
              height: "0.75rem",
              width: "0.75rem",
            }}
          />
        </button>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "24px",
        }}
      >
        {visibleFaqs.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            isOpen={openIndex === index}
            onClick={() => handleToggle(index)}
          />
        ))}
        <button
          onClick={handleShowMore}
          style={{
            cursor: "pointer",
            border: "none",
            padding: "12px 48px",
            backgroundColor: "#ECECFB",
            borderRadius: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            color: "#423FD3",
            fontSize: "16px",
            fontWeight: "600",
            textTransform: "uppercase",
          }}
        >
          {showMore ? t("HomePageConcept.FrameComponent.show_less") : t("HomePageConcept.FrameComponent.show_more")}
        </button>
      </div>
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
