// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const rootElement = document.getElementById('root');
ReactDOM.hydrateRoot(rootElement, <App />);

serviceWorkerRegistration.unregister();
