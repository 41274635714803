import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import Pricing from './Pricing';
import Header from "../HomePage/components/subcomponents/Header";
import FrameComponent from "../HomePage/components/FrameComponent";
import TrySkillQBackground from "../HomePage/components/TrySkillQBackground";
import Footer from "../HomePage/components/Footer";
import MobileHeader from "../HomePage/components_mobile/subcomponents/Header";
import MobileFrameComponent from "../HomePage/components_mobile/MobileFrameComponent";
import MobileTrySkillQ from "../HomePage/components_mobile/MobileTrySkillQ";
import MobileFooter from "../HomePage/components_mobile/MobileFooter";

const PricingMain = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div>
      {isMobile ? (
        <>
          <MobileHeader />
          <Pricing />
          <MobileFrameComponent />
          <MobileTrySkillQ />
          <MobileFooter />
        </>
      ) : (
        <>
          <Header />
          <Pricing />
          <FrameComponent />
          <TrySkillQBackground />
          <Footer />
        </>
      )}
    </div>
  );
};

export default PricingMain;
