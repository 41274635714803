import { analytics } from '../../../../../shared/firebase/firebase'; // Ensure the path is correct
import { logEvent } from 'firebase/analytics';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';  // Import i18n hook
import PropTypes from "prop-types";
import LanguageSwitcher from '../../../../../shared/components/LanguageSwitcher'; // Import LanguageSwitcher

const Header = () => {
  const { t } = useTranslation();  // Initialize translation hook

  const logAnalyticsEvent = (eventName) => {
    logEvent(analytics, `loggedout_homepage_Header${eventName}`);
  };

  // State to handle hover for the Solutions dropdown
  const [isSolutionsHovered, setIsSolutionsHovered] = useState(false);

  return (
    <header
      style={{
        width: "calc(100% - 9vw)",
        margin: "0 3vw 8vh 3vw",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        top: "0",
        zIndex: "99",
        position: "sticky",
        textAlign: "left",
        fontSize: "1rem",
        color: "#000",
        fontFamily: "Poppins",
        padding: "0 3vw",
        boxSizing: "border-box",
      }}
    >
      <Link to="/" onClick={() => logAnalyticsEvent('logo_click')}>
        <img
          style={{
            height: "10vh",
            width: "auto",
            objectFit: "cover",
          }}
          loading="lazy"
          alt="SkillQ Logo"
          src={`${process.env.PUBLIC_URL}/skillQ_logo.png`}
        />
      </Link>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "2vw",
        }}
      >
        {/* Solutions Dropdown */}
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            fontWeight: "500",
          }}
          onMouseEnter={() => setIsSolutionsHovered(true)}
          onMouseLeave={() => setIsSolutionsHovered(false)}
          onClick={() => logAnalyticsEvent('solutions_click')}
        >
          <span style={{ userSelect: "none" }}>
            {t('HomePageConcept.Header.solutions', { defaultValue: "Solutions" })}
          </span>
          {/* Dropdown Menu */}
          {isSolutionsHovered && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                left: "0",
                backgroundColor: "#fff",
                boxShadow: "0px 8px 16px rgba(0,0,0,0.2)",
                zIndex: "1",
                minWidth: "150px",
                borderRadius: "4px",
                overflow: "hidden",
              }}
            >
              <Link
                to="/"
                style={{
                  padding: "12px 16px",
                  display: "block",
                  textDecoration: "none",
                  color: "#000",
                  whiteSpace: "nowrap",
                }}
                onClick={() => {
                  logAnalyticsEvent('general_interview_click');
                  setIsSolutionsHovered(false);
                }}
              >
                {t('HomePageConcept.Header.general_interview', { defaultValue: "General Interview" })}
              </Link>
              <Link
                to="/home-page-tech"
                style={{
                  padding: "12px 16px",
                  display: "block",
                  textDecoration: "none",
                  color: "#000",
                  whiteSpace: "nowrap",
                }}
                onClick={() => {
                  logAnalyticsEvent('technical_interview_click');
                  setIsSolutionsHovered(false);
                }}
              >
                {t('HomePageConcept.Header.technical_interview', { defaultValue: "Technical Interview" })}
              </Link>
            </div>
          )}
        </div>

        {/* Pricing Link */}
        <Link
          to="/pricing"
          style={{
            textDecoration: "none",
            letterSpacing: "-0.01em",
            fontWeight: "500",
            color: "inherit",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => logAnalyticsEvent('pricing_click')}
        >
          {t('HomePageConcept.Header.pricing', { defaultValue: "Pricing" })}
        </Link>

        <Link
          to="/ContactForm"
          style={{
            textDecoration: "none",
            letterSpacing: "-0.01em",
            fontWeight: "500",
            color: "inherit",
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
          onClick={() => logAnalyticsEvent('book_demo_click')}
        >
          {t('HomePageConcept.Header.book_demo', { defaultValue: "Book a demo" })}
        </Link>

        {/* Blog Link */}
        <Link
          to="/blog"
          style={{
            textDecoration: "none",
            letterSpacing: "-0.01em",
            fontWeight: "500",
            color: "inherit",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => logAnalyticsEvent('blog_click')}
        >
          {t('HomePageConcept.Header.blog', { defaultValue: "Blog" })}
        </Link>

        {/* LanguageSwitcher Component */}
        <LanguageSwitcher />

        {/* Login Button */}
        <Link
          to="/login"
          onClick={() => logAnalyticsEvent('login_button_click')}
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <button
            style={{
              cursor: "pointer",
              border: "none",
              padding: "1vh 2vw",
              backgroundColor: "#423fd3",
              borderRadius: "100px",
              color: "#fff",
              fontSize: "1rem",
              letterSpacing: "-0.01em",
              fontWeight: "500",
              fontFamily: "Poppins",
            }}
          >
            {t('HomePageConcept.Header.login', { defaultValue: "Login" })}
          </button>
        </Link>
      </div>
    </header>
  );
};

Header.propTypes = {
  // Add prop types if needed
};

export default Header;
