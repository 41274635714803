import React, { memo, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import AOS from "aos";
import { useTheme } from "@mui/material/styles";
import "aos/dist/aos.css";
import CookieRulesDialog from "./cookies/CookieRulesDialog";
import CookieConsent from "./cookies/CookieConsent";
import Routing from "./Routing";
import smoothScrollTop from "../../shared/functions/smoothScrollTop";
import { Box } from "@mui/material";

const NAV_HEIGHT = 100; // Constant for navheight

function Main() {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(null);
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]); // Assuming you are fetching blog posts
  const [isCookieRulesDialogOpen, setIsCookieRulesDialogOpen] = useState(false);

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  const selectHome = useCallback(() => {
    smoothScrollTop();
    document.title =
      "SkillQ - AI-powered voice agent for recrutement at scale";
    setSelectedTab("For Talents");
  }, [setSelectedTab]);

  const selectBlog = useCallback(() => {
    smoothScrollTop();
    document.title = "SkillQ - HR";
    setSelectedTab("For HR");
  }, [setSelectedTab]);

  const selectTab = useCallback((tab) => {
    setSelectedTab(tab);
    // Additional logic if needed
  }, [setSelectedTab]);

  const handleMobileDrawerOpen = useCallback(() => {
    setIsMobileDrawerOpen(true);
  }, [setIsMobileDrawerOpen]);

  const handleMobileDrawerClose = useCallback(() => {
    setIsMobileDrawerOpen(false);
  }, [setIsMobileDrawerOpen]);

  const handleCookieRulesDialogOpen = useCallback(() => {
    setIsCookieRulesDialogOpen(true);
  }, [setIsCookieRulesDialogOpen]);

  const handleCookieRulesDialogClose = useCallback(() => {
    setIsCookieRulesDialogOpen(false);
  }, [setIsCookieRulesDialogOpen]);

  return (
    <Box sx={{ backgroundColor: theme.palette.common.white, overflowX: "hidden" }}>
      {!isCookieRulesDialogOpen && (
        <CookieConsent
          handleCookieRulesDialogOpen={handleCookieRulesDialogOpen}
        />
      )}
      <CookieRulesDialog
        open={isCookieRulesDialogOpen}
        onClose={handleCookieRulesDialogClose}
      />
      <Routing
        blogPosts={blogPosts}
        selectHome={selectHome}
        selectBlog={selectBlog}
        navheight={NAV_HEIGHT} // Pass navheight constant to Routing
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        mobileDrawerOpen={isMobileDrawerOpen}
        handleMobileDrawerOpen={handleMobileDrawerOpen}
        handleMobileDrawerClose={handleMobileDrawerClose}
      />
    </Box>
  );
}

Main.propTypes = {
  classes: PropTypes.object,
};

export default memo(Main);
