import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import yaml from 'js-yaml';
import './ArticleList.css'; // Import the CSS file for styling

const ArticleList = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const articleFiles = [
      `${process.env.PUBLIC_URL}/blog/ai-powered-recruitment-tools.md`,
      `${process.env.PUBLIC_URL}/blog/automated-candidate-screening-platforms.md`,
      `${process.env.PUBLIC_URL}/blog/high-volume-hiring-solutions.md`,
    ];

    const fetchArticles = async () => {
      const promises = articleFiles.map(async (filePath) => {
        try {
          const response = await fetch(filePath);
          if (!response.ok) {
            console.error(`Failed to fetch ${filePath}: ${response.statusText}`);
            return null;
          }
          const text = await response.text();

          const regex = /^---\n([\s\S]*?)\n---\n?/;
          const match = text.match(regex);

          if (match) {
            const yamlText = match[1];
            const data = yaml.load(yamlText);
            return data;
          } else {
            console.warn(`No front matter found in ${filePath}`);
            return null;
          }
        } catch (error) {
          console.error(`Error fetching ${filePath}:`, error);
          return null;
        }
      });

      const fetchedArticles = await Promise.all(promises);
      const validArticles = fetchedArticles.filter((article) => article !== null);
      setArticles(validArticles);
    };

    fetchArticles();
  }, []);

  return (
    <div className="article-list">
      <h1>Articles</h1>
      <div className="article-grid">
        {articles.map((article, index) => (
          <div key={index} className="article-card">
            <Link to={`/blog/${article.slug}`} className="article-link">
              <h2 className="article-title">{article.title}</h2>
              {article.hero && article.hero.image && (
                <img
                  src={`${process.env.PUBLIC_URL}/images/${article.hero.image.file}`}
                  alt={article.hero.image.alt}
                  className="article-image"
                />
              )}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticleList;
