import React from 'react';
import PropTypes from 'prop-types';
import Header from './subcomponents/Header';
import LeftSide from './subcomponents/LeftSide';
import RightSide from './subcomponents/RightSide';
import './HeroSection.css';

const HeroSection = ({ headerText, leftContent, rightContent }) => {
  return (
    <div className="heroSection">
      <div className="header">
        <Header>{headerText}</Header>
      </div>
      <div className="content">
        <div className="leftSide">
          <LeftSide>{leftContent}</LeftSide>
        </div>
        <div className="rightSide">
          <RightSide>{rightContent}</RightSide>
        </div>
      </div>
    </div>
  );
};

HeroSection.propTypes = {
  headerText: PropTypes.string.isRequired,
  leftContent: PropTypes.node.isRequired,
  rightContent: PropTypes.node.isRequired,
};

export default HeroSection;
