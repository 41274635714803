import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Hidden,
  TextField,
  useMediaQuery,
  Button,
  Link,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const FooterInner = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.darkBlack,
  paddingTop: theme.spacing(8),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.up("sm")]: {
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingBottom: theme.spacing(10),
  },
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
}));

const InfoIconButton = styled(IconButton)(({ theme }) => ({
  color: `${theme.palette.common.white} !important`,
  backgroundColor: "#33383b !important",
}));

const WhiteBgTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}));

const infos = [
  {
    icon: <MailIcon />,
    description: "info@skillq.org",
  },
  {
    icon: <LocationOnIcon />,
    description: "Singapore, 68 Circular Road, #02-01",
  },
];

function Footer() {
  const theme = useTheme();
  const isWidthUpMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <footer className="lg-p-top">
      <FooterInner>
        <Grid container spacing={isWidthUpMd ? 10 : 5}>
          <Grid item xs={12} md={6} lg={4}>
            <Box display="flex" flexDirection="column">
              <Typography variant="h6" sx={{ color: theme.palette.common.white }}>
                Contact Us
              </Typography>
              <Link href="/ContactForm" underline="none" sx={{ marginTop: theme.spacing(2) }}>
                <Button color="primary" variant="contained">
                  Schedule a Call
                </Button>
              </Link>
            </Box>
          </Grid>
          <Hidden lgDown>
            <Grid item xs={12} md={6} lg={4}>
              <Box display="flex" justifyContent="center">
                <div>
                  {infos.map((info, index) => (
                    <Box display="flex" mb={1} key={index}>
                      <Box mr={2}>
                        <InfoIconButton tabIndex={-1} disabled size="large">
                          {info.icon}
                        </InfoIconButton>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        sx={info.description === "Singapore, 68 Circular Road, #02-01" ? { maxWidth: '200px' } : {}}
                      >
                        <Typography variant="h6" sx={{ color: theme.palette.common.white }}>
                          {info.description}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </div>
              </Box>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant="h6" paragraph sx={{ color: theme.palette.common.white }}>
              About the Company
            </Typography>
            <Typography sx={{ color: theme.palette.common.white }} paragraph>
              SkillQ is an innovative AI-powered platform that transforms tech
              talent assessment
            </Typography>
          </Grid>
        </Grid>
      </FooterInner>
    </footer>
  );
}

Footer.propTypes = {
  theme: PropTypes.object,
};

export default Footer;
