import { analytics } from '../../../../shared/firebase/firebase'; // Ensure the path is correct
import { logEvent } from 'firebase/analytics';

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next'; // Import the translation hook

// Subcomponent: Header
const Header = () => {
  const { t } = useTranslation(); // Initialize translation hook

  const logAnalyticsEvent = () => {
    logEvent(analytics, 'loggedout_homepage_MobileValueProposition_header_view');
  };

  useEffect(() => {
    logAnalyticsEvent();
  }, []);

  return (
    <div style={{
      backgroundColor: "transparent",
      padding: "20px",
      borderRadius: "10px",
      width: "100%",
      boxSizing: "border-box",
      textAlign: "left"
    }}>
      <div>
        <div style={{
          borderRadius: "70px",
          backgroundColor: "#fff",
          border: "1px solid #ececfb",
          padding: "6px 16px",
          display: "inline-block",
          whiteSpace: "nowrap",
          color: "#423fd3",
          textTransform: "uppercase",
          fontWeight: "500",
          fontFamily: "Poppins"
        }}>
          {t("HomePageConcept.ValueProposition.why_choose_us")}
        </div>
      </div>
      <h1 style={{
        margin: "20px 0 10px 0",
        width: "100%",
        position: "relative",
        fontSize: "24px",
        lineHeight: "28px",
        fontWeight: "600",
        color: "#fff"
      }}>
        {t("HomePageConcept.ValueProposition.fast_track")}
      </h1>
      <div style={{
        fontSize: "14px",
        lineHeight: "20px",
        color: "#fff"
      }}>
        {t("HomePageConcept.ValueProposition.description")}
      </div>
    </div>
  );
};

// Subcomponent: BulletPoint
const BulletPoint = ({ title, description, imageSrc, isOpen, onClick }) => {
  return (
    <div style={{
      backgroundColor: "#fff",
      borderRadius: "10px",
      boxSizing: "border-box",
      marginBottom: isOpen ? "40px" : "20px",
      padding: "10px",
      cursor: "pointer",
      width: "100%"
    }} onClick={onClick}>
      <div style={{
        display: "flex",
        alignItems: "center",
        padding: "10px",
        borderRadius: "10px",
        backgroundColor: "#fff",
        width: "100%"
      }}>
        <img style={{
          height: "32px",
          width: "32px",
          marginRight: "12px",
          flexShrink: "0"
        }} alt="" src={imageSrc} />
        <h3 style={{
          margin: "0",
          fontSize: "20px",
          fontWeight: "500",
          flexGrow: "1",
          color: "#1e2225"
        }}>{title}</h3>
        <img style={{
          height: "24px",
          width: "11px",
          flexShrink: "0",
          objectFit: "contain"
        }} alt="" src={isOpen ? `${process.env.PUBLIC_URL}/images/landing/formkit_arrowright.png` : `${process.env.PUBLIC_URL}/images/landing/formkit_arrowright_1.png`} />
      </div>
      {isOpen && (
        <div style={{
          padding: "10px 20px",
          backgroundColor: "#fff",
          color: "#1e2225",
          width: "100%"
        }}>
          {description}
        </div>
      )}
    </div>
  );
};

BulletPoint.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  imageSrc: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

// Subcomponent: LeftSide
const LeftSide = ({ bulletPoints, openBullet, setOpenBullet }) => {
  const logAnalyticsEvent = (eventName) => {
    logEvent(analytics, `loggedout_homepage_MobileValueProposition_${eventName}`);
  };

  return (
    <div style={{
      width: "100%",
      maxWidth: "800px",
      boxSizing: "border-box",
      backgroundColor: "transparent",
      borderRadius: "10px",
      padding: "20px",
      marginBottom: "20px"
    }}>
      {bulletPoints.map((bullet, index) => (
        <div key={index} style={{
          marginBottom: "20px",
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: "#fff",
          width: "100%"
        }}>
          <BulletPoint
            title={bullet.title}
            description={bullet.description}
            imageSrc={bullet.imageSrc}
            isOpen={openBullet === index}
            onClick={() => {
              logAnalyticsEvent(`bullet_${index}_click`);
              setOpenBullet(openBullet === index ? null : index);
            }}
          />
        </div>
      ))}
    </div>
  );
};

LeftSide.propTypes = {
  bulletPoints: PropTypes.array.isRequired,
  openBullet: PropTypes.number,
  setOpenBullet: PropTypes.func.isRequired,
};

// Main component: ValueProposition
const ValueProposition = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const [openBullet, setOpenBullet] = useState(0); // Default to first bullet open

  const bulletPoints = [
    {
      title: t("HomePageConcept.ValueProposition.bullet_1_title"),
      description: (
        <p>
          {t("HomePageConcept.ValueProposition.bullet_1_description")}
        </p>
      ),
      imageSrc: `${process.env.PUBLIC_URL}/images/landing/fluent_video-person-16-filled.webp`
    },
    {
      title: t("HomePageConcept.ValueProposition.bullet_2_title"),
      description: (
        <p>
          {t("HomePageConcept.ValueProposition.bullet_2_description")}
        </p>
      ),
      imageSrc: `${process.env.PUBLIC_URL}/images/landing/heroicons_adjustments-horizontal-20-solid.webp`
    },
    {
      title: t("HomePageConcept.ValueProposition.bullet_3_title"),
      description: (
        <p>
          {t("HomePageConcept.ValueProposition.bullet_3_description")}
        </p>
      ),
      imageSrc: `${process.env.PUBLIC_URL}/images/landing/mdi_medal.webp`
    },
  ];

  return (
    <div style={{
      alignSelf: "stretch",
      backgroundColor: "#1c1a59",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      boxSizing: "border-box",
      maxWidth: "100%",
      zIndex: "5",
      textAlign: "left",
      fontSize: "14px",
      color: "#fff",
      fontFamily: "Poppins"
    }}>
      <Header />
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        paddingTop: "40px",
        boxSizing: "border-box"
      }}>
        <LeftSide bulletPoints={bulletPoints} openBullet={openBullet} setOpenBullet={setOpenBullet} />
      </div>
    </div>
  );
};

export default ValueProposition;
