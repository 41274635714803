import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";  // Import i18n hook
import Content from "./Content";
import PropTypes from "prop-types";

const CandidateBackground = ({ className = "" }) => {
  const { t } = useTranslation();  // Initialize translation
  const [maxHeight, setMaxHeight] = useState(0);

  const handleSetHeight = (height) => {
    setMaxHeight((prevHeight) => Math.max(prevHeight, height));
  };

  useEffect(() => {
    const contentElements = document.querySelectorAll('.content-box');
    contentElements.forEach(element => {
      element.style.height = `${maxHeight}px`;
    });
  }, [maxHeight]);

  return (
    <div
      style={{
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: "0px 88px 150px 104px",
        gap: "49px",
        maxWidth: "100%",
        textAlign: "left",
        fontSize: "14px",
        color: "#423fd3",
        fontFamily: "Poppins",
      }}
      className={className}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "20px",
          maxWidth: "100%",
        }}
      >
        <div
          style={{
            borderRadius: "70px",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            border: "1px solid #ececfb",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "6px 16px",
          }}
        >
          <div
            style={{
              position: "relative",
              letterSpacing: "0.02em",
              textTransform: "uppercase",
              fontWeight: "600",
              display: "inline-block",
              minWidth: "94px",
            }}
          >
            {t('HomePageConcept.CandidateBackground.testimonial', { defaultValue: "testimonial" })}
            </div>
        </div>
        <h1
          style={{
            margin: "0",
            position: "relative",
            fontSize: "40px",
            lineHeight: "48px",
            fontWeight: "600",
            fontFamily: "inherit",
            color: "#0b0b24",
          }}
        >
          <p style={{ margin: "0" }}>
          <span style={{ color: "#0b0b24" }}>{t('HomePageConcept.CandidateBackground.see_what', { defaultValue: "See what" })}</span>
          <span style={{ color: "#423fd3" }}>{t('HomePageConcept.CandidateBackground.candidates', { defaultValue: "candidates" })}</span>
          <span>{t('HomePageConcept.CandidateBackground.say', { defaultValue: "say" })}</span>
          </p>
          <p style={{ margin: "0" }}>
          <span>{t('HomePageConcept.CandidateBackground.about', { defaultValue: "about" })}</span>
            <span style={{ color: "#423fd3" }}>{t('HomePageConcept.CandidateBackground.SkillQ', { defaultValue: "SkillQ" })}</span>
            <span style={{ color: "#0b0b24" }}>{t('HomePageConcept.CandidateBackground.agent', { defaultValue: "agent" })}</span>
          </p>
        </h1>
      </div>
      <div
        style={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "24px",
          maxWidth: "100%",
          fontSize: "16px",
          color: "#000",
        }}
      >
        <Content
          className="content-box"
          screenShot={`${process.env.PUBLIC_URL}/images/landing/Screen Shot 2023-11-06 at 07.50 1.webp`}
          text={t('HomePageConcept.CandidateBackground.david_text', { defaultValue: "It felt like I am talking to a real person, conversation was very comfortable and easy going. I think I managed to show my best with SkillQ agent as I felt less pressure compared to traditional interview" })}
          name="David"
          position="Senior Data Scientist"
          setHeight={handleSetHeight}
        />
        <Content
          className="content-box"
          screenShot={`${process.env.PUBLIC_URL}/images/landing/Screen Shot 2023-11-06 at 07.50 1 (1).webp`}
          text={t('HomePageConcept.CandidateBackground.ben_text', { defaultValue: "I can do interview at any time of the day which is amazing. Agent sounds very professional, I would use it for mock interviews as well" })}
          name="Ben"
          position="Data Engineer"
          setHeight={handleSetHeight}
        />
        <Content
          className="content-box"
          screenShot={`${process.env.PUBLIC_URL}/images/landing/Screen Shot 2023-11-06 at 07.50 1 (2).webp`}
          text={t('HomePageConcept.CandidateBackground.adam_text', { defaultValue: "I was impressed by the agent's ability to delve deeper into my responses. It is extremely rare that recruiters go deep into my technical knowledge" })}
          name="Adam"
          position="Senior Frontend Developer"
          setHeight={handleSetHeight}
        />
      </div>
      <div
        style={{
          alignSelf: "stretch",
          borderBottom: "1px solid #d6d6d6",
          marginTop: "8vh",

        }}
      />
    </div>
  );
};

CandidateBackground.propTypes = {
  className: PropTypes.string,
};

export default CandidateBackground;
