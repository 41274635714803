import PropTypes from "prop-types";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const MobileStaticsSection = ({ className = "" }) => {
  const { t } = useTranslation(); // Initialize the translation hook

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "88px 0px",
        boxSizing: "border-box",
        lineHeight: "normal",
        letterSpacing: "normal",
      }}
      className={className}
    >
      <section
        style={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "80px",
          textAlign: "center",
          fontSize: "26px",
          color: "#0b0b24",
          fontFamily: "Poppins",
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            position: "relative",
            lineHeight: "32px",
            fontWeight: "600",
          }}
        >
          <span>{t("StaticsSection.embrace_conversational", { defaultValue: "Embrace conversational" })}</span>
          <span style={{ color: "#37374b" }}>{` `}</span>
          <span style={{ color: "#423fd3" }}>{t("StaticsSection.ai_benefits", { defaultValue: "AI benefits" })}</span>
          <span style={{ color: "#37374b" }}>{` `}</span>
          <span>{t("StaticsSection.for_your_company", { defaultValue: "for your company" })}</span>
        </div>
        <div
          style={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 26px",
            gap: "48px",
            fontSize: "48px", // Smaller font size for the text below
            color: "#37374b",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                lineHeight: "72px",
                fontWeight: "600",
              }}
            >
              150+
            </div>
            <div
              style={{
                width: "255px",
                position: "relative",
                fontSize: "20px",
                lineHeight: "28px",
                color: "rgba(0, 0, 0, 0.5)",
                display: "inline-block",
              }}
            >
              {t("StaticsSection.hours_saved", { defaultValue: "hours of the technical team saved" })}
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                lineHeight: "72px",
                fontWeight: "600",
              }}
            >
              99%
            </div>
            <div
              style={{
                position: "relative",
                fontSize: "20px",
                lineHeight: "28px",
                color: "rgba(0, 0, 0, 0.5)",
              }}
            >
              {t("StaticsSection.cheating_detection", { defaultValue: "ChatGPT cheating detection" })}
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                lineHeight: "72px",
                fontWeight: "600",
              }}
            >
              +50%
            </div>
            <div
              style={{
                width: "311px",
                position: "relative",
                fontSize: "20px",
                lineHeight: "28px",
                color: "rgba(0, 0, 0, 0.5)",
                display: "inline-block",
              }}
            >
              {t("StaticsSection.assessment_completion_increase", { defaultValue: "assessment completion rate increase" })}
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                lineHeight: "72px",
                fontWeight: "600",
              }}
            >
              95%
            </div>
            <div
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "20px",
                lineHeight: "28px",
                color: "rgba(0, 0, 0, 0.5)",
              }}
            >
              {t("StaticsSection.candidate_experience_rating", { defaultValue: "of the candidates rate the experience 5/5" })}
            </div>
          </div>
        </div>
      </section>
      <div
        style={{
          alignSelf: "stretch",
          borderBottom: "1px solid #d6d6d6",
          marginTop: "8vh",
          marginLeft: "10vw",
          marginRight: "10vw",
        }}
      />
    </div>
  );
};

MobileStaticsSection.propTypes = {
  className: PropTypes.string,
};

export default MobileStaticsSection;
