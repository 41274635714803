import React, { useState } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel, Typography, TextField, Button, Box } from '@mui/material';
import { doc, setDoc, getFirestore, Timestamp } from 'firebase/firestore';
import { auth, analytics } from '../../../../shared/firebase/firebase';
import SuccessBanner from './SuccessBanner'; // Import the SuccessBanner component
import ResendEmailNotification from './ResendEmailNotification'; // Import the ResendEmailNotification component
import { logEvent } from 'firebase/analytics';
import { useTranslation } from 'react-i18next'; // Import i18n hook

const personalEmailDomains = [
  'gmail.com', 'yahoo.com', 'hotmail.com', 'aol.com', 'outlook.com', 'icloud.com',
  'mail.ru', 'zoho.com', 'protonmail.com', 'yandex.ru', 'yopmail.com',
  'gmx.com', 'fastmail.com', 'hushmail.com', 'mail.com', 'inbox.com', 'tutanota.com',
  'rediffmail.com', 'seznam.cz', 'lycos.com', 'web.de', 'posteo.net', 'qq.com',
  'excite.com', 'mailfence.com', 'cock.li', 'msgsafe.io', 'dispostable.com', 'guerrillamail.com',
  'mailinator.com', '10minutemail.com', 'tempmail.com', 'mytrashmail.com', 'trashmail.com'
];


const FirebaseAuthComponent = ({ onSignupSuccess, onSignupError }) => {
  const { t } = useTranslation(); // Initialize translation hook
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);
  const [error, setError] = useState('');
  const [showBanner, setShowBanner] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const canSubmit = () => {
    return email.length > 0 && password.length > 0 && isAgreed;
  };

  const isBusinessEmail = (email) => {
    const domain = email.split('@')[1];
    return !personalEmailDomains.includes(domain);
  };

  const openInNewTab = (url) => {
    const newTab = window.open(url, '_blank', 'noopener,noreferrer');
    if (newTab) newTab.opener = null;
  };

  function getErrorMessage(errorCode) {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return t('UserSignupPage.errors.email_in_use', { defaultValue: 'The email address is already in use by another account.' });
      case 'auth/invalid-email':
        return t('UserSignupPage.errors.invalid_email', { defaultValue: 'The email address is invalid.' });
      case 'auth/operation-not-allowed':
        return t('UserSignupPage.errors.operation_not_allowed', { defaultValue: 'The operation is not allowed.' });
      case 'auth/weak-password':
        return t('UserSignupPage.errors.weak_password', { defaultValue: 'The password is weak.' });
      default:
        return t('UserSignupPage.errors.unknown_error', { defaultValue: 'An unknown error occurred.' });
    }
  }

  const logTime = (message) => {
    console.log(`${message} at ${new Date().toLocaleTimeString()}`);
  };

  const createAccountWithEmail = async () => {
    if (!isBusinessEmail(email)) {
      setError(t('UserSignupPage.errors.business_email_required', { defaultValue: 'Please use a business email address.' }));
      return;
    }

    logEvent(analytics, 'loggedout_CompanyRegistration_email_signup_attempt', { method: 'Email' }); // Log email signup attempt
    logTime('Email signup button clicked');

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user); // Set the user for resend verification

      await sendEmailVerification(userCredential.user);

      const db = getFirestore();
      await setDoc(doc(db, "users", userCredential.user.uid), {
        name: userCredential.user.displayName || "No Name Provided",
        email: userCredential.user.email || "No Email Provided",
        accountType: "company"
      });

      await setDoc(doc(db, "subscription_status", userCredential.user.uid), {
        subscriptionPlan: "free",
        interviewsGenerated: 0,
        candidatesPassed: 0,
        maxCandidates: 3,
        maxInterviews: 2,
        InterviewLimitReached: false,
        CandidatesLimitReached: false,
        lastResetDate: Timestamp.fromDate(new Date())
      });

      await signOut(auth);

      onSignupSuccess(userCredential.user);
      logEvent(analytics, 'loggedout_CompanyRegistration_email_signup_success', { method: 'Email', accountType: 'company' });

      setShowBanner(true);  // Show the banner on successful signup

      setTimeout(() => {
        setShowBanner(false);
        navigate('/login'); // Navigate to the login page
      }, 6000);

    } catch (error) {
      const errorMessage = getErrorMessage(error.code);
      setError(errorMessage); // Set error message based on the error code
      onSignupError(error);

      logEvent(analytics, 'loggedout_CompanyRegistration_email_signup_failed', { method: 'Email', error: error.message });
    }
  };

  return (
    <Box
      sx={{
        maxWidth: { xs: '90%', sm: '100%' },
        boxShadow: { xs: 'none', sm: 3 },
        margin: 'auto',
        padding: 2,
        borderRadius: 2,
      }}
    >
      <Typography variant="h5" gutterBottom>
        {t('UserSignupPage.signup_title', { defaultValue: 'Create Account' })}
      </Typography>
      <form onSubmit={(e) => e.preventDefault()}>
        <TextField
          fullWidth
          label={t('UserSignupPage.email_label', { defaultValue: 'Business email' })}
          type="email"
          name="email"
          value={email}
          placeholder={t('UserSignupPage.email_label', { defaultValue: 'Enter your email' })}
          onChange={(e) => {
            setEmail(e.target.value);
            setError(''); // Clear error message on email change
          }}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label={t('UserSignupPage.password_label', { defaultValue: 'Password' })}
          type="password"
          name="password"
          value={password}
          placeholder={t('UserSignupPage.password_label', { defaultValue: 'Enter your password' })}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isAgreed}
              onChange={(e) => setIsAgreed(e.target.checked)}
              name="agreement"
              color="primary"
            />
          }
          label={
            <>
              {t('UserSignupPage.agreement_text', { defaultValue: 'I have read and accept ' })}
              <a href="#" onClick={() => openInNewTab('/terms-of-service')} rel="noopener noreferrer">
                {t('UserSignupPage.terms_of_service', { defaultValue: 'Terms of Service' })}
              </a>{" "}
              {t('UserSignupPage.and', { defaultValue: 'and ' })}
              <a href="#" onClick={() => openInNewTab('/privacy-policy')} rel="noopener noreferrer">
                {t('UserSignupPage.privacy_policy', { defaultValue: 'Privacy Policy' })}
              </a>
            </>
          }
        />
        {error && (
          <Typography color="error" sx={{ marginBottom: 2 }}>
            {error}
          </Typography>
        )}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={createAccountWithEmail}
          disabled={!canSubmit()}
          sx={{ marginBottom: 2 }}
        >
          {t('UserSignupPage.register_button', { defaultValue: 'Register with Email' })}
        </Button>
        {showBanner && (
          <>
            <SuccessBanner 
              message={t('UserSignupPage.check_email_banner', { defaultValue: 'Check your email for a confirmation message' })}
              onClose={() => setShowBanner(false)}
            />
            {user && (
              <Box mt={2}>
                <ResendEmailNotification user={user} onResent={() => setShowBanner(true)} />
              </Box>
            )}
          </>
        )}
      </form>
    </Box>
  );
};

export default FirebaseAuthComponent;
