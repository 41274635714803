import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../StoreProvider'; // Update this line
import Flag from 'react-world-flags'; // Import Flag component from react-world-flags

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const userStore = useStore(); // Get your store
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Manage dropdown visibility
  const currentLang = i18n.language; // Get current language

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);

    // Update language in your store
    userStore.setLanguage(lng);
    setIsDropdownOpen(false); // Close dropdown after selecting a language
  };

  // Determine current flag code based on the selected language
  const currentFlag = currentLang === 'pt'
    ? 'PT'
    : currentLang === 'fr'
    ? 'FR'
    : currentLang === 'sp'
    ? 'ES'
    : currentLang === 'ru'
    ? 'RU'
    : 'GB'; // Default to GB for English

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        fontWeight: "500",
      }}
      onMouseEnter={() => setIsDropdownOpen(true)}
      onMouseLeave={() => setIsDropdownOpen(false)}
    >
      {/* Display current language's flag */}
      <Flag code={currentFlag} alt="Current Language" style={{ width: "24px", height: "24px", marginRight: "8px" }} />

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            left: "0",
            backgroundColor: "#fff",
            boxShadow: "0px 8px 16px rgba(0,0,0,0.2)",
            zIndex: "1",
            minWidth: "100px",
            borderRadius: "4px",
            overflow: "hidden",
          }}
        >
          <button
            style={{
              padding: "12px 16px",
              display: "block",
              width: "100%",
              background: "none",
              border: "none",
              textAlign: "left",
              cursor: "pointer",
              fontWeight: "500",
              color: "#000",
            }}
            onClick={() => changeLanguage('en')}
          >
            <Flag code="GB" alt="English" style={{ width: "24px", height: "24px", marginRight: "8px" }} />
            English
          </button>
          <button
            style={{
              padding: "12px 16px",
              display: "block",
              width: "100%",
              background: "none",
              border: "none",
              textAlign: "left",
              cursor: "pointer",
              fontWeight: "500",
              color: "#000",
            }}
            onClick={() => changeLanguage('pt')}
          >
            <Flag code="PT" alt="Português" style={{ width: "24px", height: "24px", marginRight: "8px" }} />
            Português
          </button>
          <button
            style={{
              padding: "12px 16px",
              display: "block",
              width: "100%",
              background: "none",
              border: "none",
              textAlign: "left",
              cursor: "pointer",
              fontWeight: "500",
              color: "#000",
            }}
            onClick={() => changeLanguage('fr')}
          >
            <Flag code="FR" alt="Français" style={{ width: "24px", height: "24px", marginRight: "8px" }} />
            Français
          </button>
          <button
            style={{
              padding: "12px 16px",
              display: "block",
              width: "100%",
              background: "none",
              border: "none",
              textAlign: "left",
              cursor: "pointer",
              fontWeight: "500",
              color: "#000",
            }}
            onClick={() => changeLanguage('sp')}
          >
            <Flag code="ES" alt="Español" style={{ width: "24px", height: "24px", marginRight: "8px" }} />
            Español
          </button>
          <button
            style={{
              padding: "12px 16px",
              display: "block",
              width: "100%",
              background: "none",
              border: "none",
              textAlign: "left",
              cursor: "pointer",
              fontWeight: "500",
              color: "#000",
            }}
            onClick={() => changeLanguage('ru')}
          >
            <Flag code="RU" alt="Русский" style={{ width: "24px", height: "24px", marginRight: "8px" }} />
            Русский
          </button>
        </div>
      )}
    </div>
  );
}

export default LanguageSwitcher;
