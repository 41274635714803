import React from 'react';
import PropTypes from 'prop-types';
import Header from './subcomponents/Header';
import LeftSide from './subcomponents/LeftSide';
import RightSide from './subcomponents/RightSide';
import './MobileHeroSection.css';

const MobileHeroSection = ({ headerText, leftContent, rightContent }) => {
  return (
    <div className="mobileHeroSection">
      <div className="mobileHeader">
        <Header>{headerText}</Header>
      </div>
      <div className="mobileContent">
        <div className="mobileLeftSide">
          <LeftSide>{leftContent}</LeftSide>
        </div>
        <div className="mobileRightSide">
          <RightSide>{rightContent}</RightSide>
        </div>
      </div>
    </div>
  );
};

MobileHeroSection.propTypes = {
  headerText: PropTypes.node.isRequired,
  leftContent: PropTypes.node.isRequired,
  rightContent: PropTypes.node.isRequired,
};

export default MobileHeroSection;
